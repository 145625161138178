.UsageSummary {
  /* position: relative; */
  height: 450px;
  display: flex;
  flex-direction: column;
}

.UsageSummary .snapshot {
  flex: 1;
  border-bottom: 1px solid #DDD;
  display: flex;
}

.UsageSummary .snapshot .fraction {
  font-weight: 300;
  font-size: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #DDD;
  flex: 1;
}

.UsageSummary .snapshot .date {
  display: flex;
  align-items: center;
  flex: 3;
  padding: 15px;
  font-size: 14px;
}

.UsageSummary .snapshot .date p {
  margin-right: 40px;
}

.UsageSummary .body {
  flex: 6;
  display: flex;
}

.UsageSummary .body .list {
  flex: 2;
  display: flex;
  border-right: 1px solid #DDD;
}

.UsageSummary .body .chart {
  flex: 5;
  display: flex;
  align-items: center;
}

.UsageSummary .chart .header {
  height: 20%;
}