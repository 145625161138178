.ScrollView {
  flex: 1;
  display: flex;
}

.ScrollView .scroll {
  display: flex;
}

.ScrollView .scroll.horizontal {
  overflow-x: scroll;
  overflow-y: hidden;
  flex-direction: row;
}

.ScrollView .scroll.vertical {
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
}

.ScrollView .scroll::-webkit-scrollbar {
    width: 0.5em;
    background-color: #f5f5f5;
}

.ScrollView .scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px #DDD;
}

.ScrollView .scroll::-webkit-scrollbar-thumb {
  background-color: #DDD;
  outline: 1px solid #f5f5f5;
  border-radius: 6px;
}
