.Article {
  flex: 1;
  display: flex;
  padding: 5px 10px;
}

.Article .data-row {
  padding: 5px 10px;
  border-bottom: 1px solid #DDD;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Article .remove-btn:hover {
  cursor: pointer;
}

.Article .data-row:nth-child(even) {
  background-color: #FCFCFC;
}

.Article .data-row:last-child {
  border-bottom: 0;
}

.Article .data-row.highlight {
  border: 1px solid yellow;
  background-color: yellow;
  opacity: 0.25;
}

.Article .info {
  flex: 1;
  margin: 10px;
}

.Article .info .row {
  padding: 20px;
}

.Article .markdown {
  flex: 1;
  margin: 10px;
}

.Article .markdown .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.Article .markdown .header h3, .Article .markdown .header .Button {
  margin: 0;
}

.Article .select {
  flex: 1;
  max-width: 50%;
}

.Article .content {
  background-color: white;
  border: 1px solid #DDD;
  border-radius: 3px;
  padding: 20px;
}

.Article .copy:hover {
  cursor: pointer;
}
