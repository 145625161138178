.StationAdvocateListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
}

.StationAdvocateListItem:last-child {
  border-bottom: 0px solid #ddd;
}

.StationAdvocateListItem:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.StationAdvocateListItem .info {
  display: flex;
  align-items: center;
}

.StationAdvocateListItem .status {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.StationAdvocateListItem .status .advocate p {
  color: rgb(185, 12, 15);
}

.StationAdvocateListItem .status .advocate p {
  font-size: 12px;
}

.StationAdvocateListItem .status .pending, .StationAdvocateListItem .status .complete {
  margin-left: 10px;
}
