.InputTime {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.InputTime .time input {
  font-size: 1.05em;
  font-weight: 300;
  padding: 10px;
  height: 42px;
  width: 100%;
}

.InputTime label {
  margin-bottom: 5px;
  color: #666;
  font-size: 0.85em;
  font-weight: 300;
}
