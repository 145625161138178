.StationEntry {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDD;
  padding-right: 20px;
  cursor: pointer;
  text-decoration: none;
}

.StationEntry:hover {
  background-color: #f5f5f5;
}

.StationEntry:last-child {
  border-bottom: none;
}

.StationEntry .iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  padding: 10px 0;
}

.StationEntry h3 {
  margin: 0;
  font-weight: 300;
  color: #999;
}

.StationEntry .id {
  color: black;
  margin-right: 1ch;
  text-transform: capitalize;
}

.StationEntry .city {
  text-transform: lowercase;
}

.StationEntry .city::first-letter {
  text-transform: uppercase;
}

.StationEntry .state {
  margin-left: 0.7ch;
}

.StationEntry .match {
  border-bottom: 3px solid #F9EF75;
  margin-top: 3px;
}

.StationEntry .ispCount {
  color: black;
  margin-right: 0.7ch;
}