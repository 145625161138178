.Button_OLD {
  color: white;
  font-weight: 300;
  display: block;
  border-radius: 3px;
  border: none;
  font-size: 0.9em;
  text-align: center;
  padding: 7px 15px;
  cursor: pointer;
  margin: 0 auto;
  min-width: 80px;
  min-height: 40px;
  width: 120px;
  text-decoration: none;
  text-decoration-style: none;
  text-decoration-color: none;
}

.Button_OLD:focus {
  outline: none;
}