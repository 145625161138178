/* .InputText textarea {
  padding: 0;
  margin: 0;
  width: 100%;
  border: solid 1px #ddd;
  border-radius: 5px;
  font-size: 1.05em;
  font-weight: 300;
  padding: 10px;
} */

.InputText {
  width: 100%;
  margin-top: 5px;
}

.InputText label, .InputText input {
  display: block;
}

.InputText input,
.InputText textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #DDD;
  border-radius: 5px;
  font-size: 1.05em;
  font-weight: 300;
  padding: 10px;
}

.InputText ::placeholder {
  color: #BBB;
  opacity: 1;
}

.InputText label {
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 0.85em;
  color: #666;
  display: flex;
  align-items: center;
}

.InputText label i {
  margin-left: 5px;
}

.InputTextWithSave {
  display: flex;
}

.InputTextWithSave button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.InputTextWithSave input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.InputText .invalid-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.InputText .invalid-message {
  margin-left: 10px; 
}