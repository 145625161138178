.Switch {
  width: 40px;
  height: 20px;
  border-radius: 20px;
  position: relative;
}
.Switch .circle {
  position: absolute;
  top: -1px;
  height: 22px;
  width: 22px;
  border-radius: 100%;
  /* border: solid 1px #ddd; */
  transition: .2s;
  box-shadow: 2px 2px 4px #ddd;
}

.Switch.off .circle {
  /* left: -1px; */
  transform: translateX(0px)
}

.Switch.on .circle {
  /* right: 1px; */
  transform: translateX(20px)
}