.SearchInput input {
  border: none;
  width: 100%;
  font-size: 20px;
  padding: 10px 20px;
  font-weight: 200;
}

.SearchInput input::placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #999;
}

.SearchOptions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.SearchOption {
  display: flex;
  height: 100%;
  min-width: 50px;
  font-size: 12px;
  font-weight: 400;
  margin: 5px;
  padding: 5px 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: solid 1px #bbb;
  color: #bbb;
  border-radius: 5px;
}

.SearchOption.enabled {
  color: white;
  background-color: #0477B4;
  border: solid 1px #0477B4;
}