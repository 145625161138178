.markdown {
  font-family: Helvetica;
}

.markdown hr {
  border: 0;
  border-top: 1px solid #DDD;
  margin: 15px 0;
}

.markdown table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1.5em;
}

.markdown thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.markdown table tr:nth-child(2n) {
  background: #f6f8fa;
}

.markdown th, .markdown td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

.markdown th {
  font-weight: bold;
  text-align: -internal-center;
  display: table-cell;
}

.markdown tr {
  border-top: 1px solid #c6cbd1;
  background-color: #fff;
  display: table-row;
}

.markdown td {
  display: table-cell;
  vertical-align: inherit;
}

.markdown h1, .markdown h2 {
  font-weight: 400;
  font-size: 30px;
  margin-top: 0;
}

.markdown h3 {
  font-size: 21px;
  font-weight: 700;
  margin: 9px 0;
  line-height: 28px;
}

.markdown p {
  margin: 0;
  margin-bottom: 1.5em;
}

.markdown li {
  margin-bottom: 0.5em;
}

.markdown p, .markdown td, .markdown th, .markdown li {
  font-size: 14px;
  line-height: 1.55em;
  color: #4d4d4d;
}

@media (max-width: 700px) {
  .markdown h1, .markdown h2 {
    font-size: 34px;
  }

  .markdown h3 {
    font-size: 24px;
  }

  .markdown p, .markdown td, .markdown th, .markdown li {
    font-size: 18px;
  }

  .markdown th {
    font-size: 20px;
  }
}

.markdown th {
  font-size: 16px;
}

.markdown img.snapshot {
  display: block;
  margin: 20px auto;
  max-width: 300px;
  min-width: 150px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

@media (max-width: 400px) {
  .markdown img.snapshot {
    max-width: 75%;
  }
}

.markdown blockquote {
  background-color: #f5f5f5;
  padding: 10px 0;
  margin: 0;
  margin-bottom: 1.5em;
  padding-left: 3em;
  border-left: 0.5em solid #ddd;
}

.markdown iframe {
  width: 75%;
  margin: 0 auto;
  display: block;
  height: 250px;
}

@media (max-width: 300px) {
  .markdown iframe {
    width: 275px;
    height: calc(275px / 1.78);
  }
}

@media (max-width: 350px) {
  .markdown iframe {
    width: 300px;
    height: calc(300px / 1.78);
  }
}

@media (min-width: 400px) {
  .markdown iframe {
    width: 350px;
    height: calc(350px / 1.78);
  }
}

@media (min-width: 500px) {
  .markdown iframe {
    width: 400px;
    height: calc(400px / 1.78);
  }
}

@media (min-width: 700px) {
  .markdown iframe {
    width: 450px;
    height: calc(450px / 1.78);
  }
}

@media (min-width: 900px) {
  .markdown iframe {
    width: 500px;
    height: calc(500px / 1.78);
  }
}

@media (min-width: 1000px) {
  .markdown iframe {
    width: 550px;
    height: calc(550px / 1.78);
  }
}

@media (min-width: 1100px) {
  .markdown iframe {
    width: 600px;
    height: calc(600px / 1.78);
  }
}

@media (min-width: 1200px) {
  .markdown iframe {
    width: 650px;
    height: calc(650px / 1.78);
  }
}

.markdown blockquote p {
  margin: 0;
}
