.UserEntry {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  flex-direction: column;
  box-sizing: border-box;
}

.UserEntry .top:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.UserEntry .top {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px 10px 10px 0;
  min-height: 50px;
}

.UserEntry .activeLightWrap {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UserEntry .activeLight {
  background-color: #DDD;
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.UserEntry .activeLight.active {
  background-color: #168E32;
}

.UserEntry .identity {
  display: flex;
  align-items: center;
}

.UserEntry .misc {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.UserEntry .contact {
  display: flex;
  min-width: 50px;
  justify-content: flex-end;
}

.UserEntry .authorization {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.UserEntry:last-child {
  border-bottom: none;
}

.UserEntry.fade {
  opacity: 0.8;
  background-color: #f5f5f5;
}

.UserEntry.fade .top:hover {
  cursor: default;
  background-color: #f5f5f5;
}

.UserEntry .iconWrap {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UserEntry .waWrap {
  padding: 5px 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #DDD;
}

.UserEntry .waWrap p {
  color: black;
}

.UserEntry .userName {
  margin: 0;
  font-weight: 300;
  flex: 1;
  font-size: 16px;
}

.UserEntry > .mdi:last-child {
  margin-left: 10px;
}

.UserEntry .bcLabel, .UserEntry .driverLabel {
  font-size: 12px;
}

.UserEntry .bcLabel {
  color: #006DAB;
  font-weight: 400;
}

.UserEntry .driverLabel {
  color: #FF9200;
  font-weight: 400;
}

.UserEntry .bottom {
  padding: 10px 50px;
  border-top: 1px solid #f5f5f5;
}

.UserEntry .bottom .contact, .UserEntry .bottom .app-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}

.UserEntry .bottom .contact {
  align-items: flex-start;
}

.UserEntry .bottom .info {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.UserEntry .bottom .info p {
  margin-left: 5px;
}

.UserEntry .actions {
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f5f5f5;
}

.UserEntry .actions .option {
  padding: 5px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
}

.UserEntry .actions .option:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.UserEntry .userId {
  color: #999;
  margin: 3px 0 0 0;
}

.UserEntry .currentOp {
  background-color: #B90C0F;
  padding: 2px 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.UserEntry .currentOp p {
  color: white;
  margin-left: 5px;
  font-weight: 400;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .UserEntry .nameWrap {
    display: flex;
  }

  .UserEntry .userId {
    margin: 0 6px;
  }

  .UserEntry .bottom {
    display: flex;
  }

  .UserEntry .bottom .app-data {
    align-items: flex-end;
  }
}
