.AddNote {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DDD;
  position: relative;
}

.AddNote form {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AddNote input {
  width: 100%;
  border: none;
  padding: 15px 10px;
  font-size: 16px;
}

.AddNote .btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 0;
  padding: 10px;
}

.AddNote button {
  outline: none;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  margin-left: 5px;
  color: white;
}

.AddNote button:hover {
  cursor: pointer;
}

.AddNote button.submit {
  background-color: rgb(22, 142, 50);
}

.AddNote button.submit:hover, .AddNote button.delete:hover {
  opacity: 0.8;
}

.AddNote button.delete {
  background-color: rgb(185, 12, 15);
}
