.StationSummary {
  height: 300px;
  display: flex;
}

.StationSummary .left, .StationSummary .middle, .StationSummary .right {
  width: 33.33%;
  height: 100%;
}

.StationSummary .left {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #DDD;
  flex-direction: column;
  position: relative;
}

.StationSummary .right {
  border-left: 1px solid #DDD;
}

.StationSummary .fraction {
  font-weight: 300;
  font-size: 24px;
  margin: 0;
  border-bottom: 1px solid #DDD;
  padding: 0 30px 15px;
  margin-bottom: 15px;
}

.StationSummary .fractionTitle {
  margin: 0;
  color: #999;
}

.StationSummary .fractionPercent {
  position: absolute;
  bottom: 0;
  color: #999;
}

.StationSummary .scroll {
  overflow: scroll;
}
