.IspEntry {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDD;
  padding-right: 20px;
  padding: 10px 20px 10px 0;
  cursor: pointer;
  text-decoration: none;
}

.IspEntry:hover {
  background-color: #f5f5f5;
}

.IspEntry.inactive:hover {
  background-color: transparent;
}

.IspEntry:last-child {
  border-bottom: none;
}

.IspEntry .iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  padding: 10px 0;
}

.IspEntry .entryBody {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.IspEntry .entryBody .actions {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.IspEntry .entryBody .isp-status {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 20px;
}

.IspEntry .entryBody .isp-status .fbEntry, .IspEntry .entryBody .isp-status .crmEntry {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.IspEntry .entryBody .isp-status .crmEntry h4 {
  font-size: 12px;
  margin-right: 5px;
}

.IspEntry h2 {
  color: #999;
  font-weight: 300;
  margin: 0;
  font-size: 18px;
}

.IspEntry h4 {
  color: #999;
  font-weight: 300;
  margin: 0;
  font-size: 13px;
}

.IspEntry h2 > span, .IspEntry h4 > span {
  color: black;
}

.IspEntry .ispTitle {
  display: flex;
  align-items: center;
}

.IspEntry .ispTitle > span:last-child {
  color: #999;
}

.IspEntry .ispTitle > span {
  maxWidth: 50%;
  display: block;
}

.IspEntry .stationWrap {
  display: flex;
  align-items: center;
  width: 80px;
}

.IspEntry .stationWrap > h4 {
  margin-left: 5px;
}

.IspEntry .paidStatus, .IspEntry .unpaidStatus {
  font-weight: 400;
}

.IspEntry .paidStatus {
  color: #168E32;
}

.IspEntry .unpaidStatus {
  color: #999;
}

.IspEntry .routes {
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px;
}

.IspEntry .match {
  border-bottom: 3px solid #F9EF75;
  margin-top: 3px;
}

.IspEntry .owner {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.IspEntry .owner > h4 {
  margin-left: 5px;
}
