.InputSlider {
  display: flex;
  width: '100%';
  flex-direction: column;
  justify-content: center;
}

.InputSlider .label {
  display: block;
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 0.85em;
  color: #666;
}
