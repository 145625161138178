.ContractSummary {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  min-height: 100px;
}

.ContractSummary .iconWrap {
  width: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #006DAB;
}

.ContractSummary .iconWrap i {
  font-size: 35px;
}

.ContractStatusDescription {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  flex: 1;
  padding: 20px;
}

.ContractStatusDescription p strong {
  margin-right: 10px;
}

.ContractSummary .contract-dates {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
@media screen and (min-width: 768px) {
}