.UserStationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  height: 50px;
}

.UserStationItem .options {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.UserStationItem .iconWrap {
  margin-right: 10px;
}

.UserStationItem h3 {
  margin: 0;
  font-weight: 300;
  color: #999;
}

.UserStationItem .id {
  color: black;
  margin-right: 1ch;
  text-transform: capitalize;
}
