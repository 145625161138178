.ActionSheet {
  width: 300px;
  height: fit-content;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px #DDD;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.ActionSheet nav {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.ActionSheet .ActionOption {
  height: 30px;
  border-bottom: solid 1px #DDD;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.ActionSheet .ActionOption.cancelOption {
  color: red;
}

.ActionSheet .ActionOption:hover {
  opacity: .5;
  transition: .3s;
}

.ActionSheet .ActionOption:last-child {
  border-bottom: none;
}