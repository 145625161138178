.Form {
  display: flex;
  padding: 10px;
  z-index: 99;
  background-color: white;
}

.Form form {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.Form .section {
  flex: 1;
  padding: 5px;
}

.Form .inputs {
  width: '100%';
  display: flex;
}

.Form .submit {
  padding: 20px;
}

.Form .input-container {
  margin-bottom: 20px;
}

.Form .half-width {
  width: '50%';
}
