.UserSearchEntry {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  border-bottom: 1px solid #DDD;
  padding-right: 20px;
  padding: 10px 20px 10px 0;
  cursor: pointer;
  text-decoration: none;
}

.UserSearchEntry:hover {
  background-color: #f5f5f5;
}

.UserSearchEntry.inactive:hover {
  background-color: transparent;
}

.UserSearchEntry:last-child {
  border-bottom: none;
}

.UserSearchEntry .iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  padding: 10px 0;
}

.UserSearchEntry .entryBody {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.UserSearchEntry .entryBody .actions {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UserSearchEntry .entryBody .isp-status {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 20px;
}

/* Includes pre-existing mention of .UserSearchEntry...? */
.UserSearchEntry .entryBody .isp-status .fbEntry, .UserSearchEntry .entryBody .isp-status .crmEntry {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UserSearchEntry .entryBody .isp-status .crmEntry h4 {
  font-size: 12px;
  margin-right: 5px;
}

.UserSearchEntry h2 {
  color: #999;
  font-weight: 300;
  margin: 0;
  font-size: 18px;
}

.UserSearchEntry h4 {
  color: #999;
  font-weight: 300;
  margin: 0;
  font-size: 13px;
}

.UserSearchEntry h2 > span, .UserSearchEntry h4 > span {
  color: black;
}

.UserSearchEntry .nameTitle {
  display: flex;
  align-items: center;
}

.UserSearchEntry .nameTitle > span:last-child {
  color: #999;
}

.UserSearchEntry .nameTitle > span {
  maxWidth: 50%;
  display: block;
}

.UserSearchEntry .detailsWrap {
  margin-top: 10px;
  display: flex;
}

.UserSearchEntry .detail {
  display: flex;
  align-items: center;
  margin-right: 10px;
  /* width: 80px; */
}

.UserSearchEntry .detail > h4 {
  margin-left: 5px;
}

.UserSearchEntry .paidStatus, .UserSearchEntry .unpaidStatus {
  font-weight: 400;
}

.UserSearchEntry .paidStatus {
  color: #168E32;
}

.UserSearchEntry .unpaidStatus {
  color: #999;
}

.UserSearchEntry .routes {
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px;
}

.UserSearchEntry .match {
  border-bottom: 3px solid #F9EF75;
  margin-top: 3px;
}

.UserSearchEntry .owner {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.UserSearchEntry .owner > h4 {
  margin-left: 5px;
}
