.Signups {
    overflow: visible;
}

.cohort {
  padding: 0 10px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
