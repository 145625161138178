.StationsSearchList {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.StationsSearchList .search {
  height: 50px;
  display: flex;
  border-bottom: 1px solid #ddd;
  align-items: center;
  justify-content: space-between;
}

.StationsSearchList .search input {
  width: 100%;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
}

.StationsSearchList .station-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.StationsSearchList .station-item:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.StationsSearchList .station-item .station-info {
  display: flex;
  align-items: center;
}

.StationsSearchList .station-item .station-info p {
  margin-left: 5px;
}

.StationsSearchList .station-item .station-isps {
  display: flex;
  align-items: center;
}

.StationsSearchList .station-item .station-isps p {
  font-size: 14px;
}

.StationsSearchList .search .sort-by {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 3px;
  height: 25px;
  width: 100px;
}

.StationsSearchList .search .sort-by:hover {
  opacity: 0.9;
  cursor: pointer;
}

.StationsSearchList .search .sort-by.active {
  background-color: rgb(0, 109, 171);
  border-color: rgb(0, 109, 171);
}

.StationsSearchList .search .sort-by.active p {
  color: white;
}

.StationsSearchList .search .sort-by p {
  font-size: 12px;
}
