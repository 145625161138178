.InputDate {
  width: 100%;
}

.InputDate label, .InputDate input {
  display: block;
}

.InputDate input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #DDD;
  border-radius: 5px;
  font-size: 1.05em;
  font-weight: 300;
  padding: 10px;
}

.InputDate ::placeholder {
  color: #BBB;
  opacity: 1;
}

.InputDate label {
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 0.85em;
  color: #666;
}
