.ContactIdChange {
  display: flex;
  flex-direction: column;
  max-height: 800px;
  max-width: 1200px;
  background-color: white;
  z-index: 25;
  justify-content: space-between;
  border-radius: 5px;
  position: relative;
  padding: 10px;
}

.ContactIdChange .submit-btn:hover {
  cursor: pointer;
}

.ContactIdChange .testTableWrapper {
  border: 1px solid #DDD;
  background-color: #f5f5f5;
  overflow: scroll;
  padding: 10px 0;
  margin: 20px 0;
  max-height: 300px;
}

.ContactIdChange .testTableWrapper::-webkit-scrollbar {
  display: none;
}

.ContactIdChange .testTableWrapper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}