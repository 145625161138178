.Notes {
  display: flex;
  flex: 1;
  margin: 0;
  flex-direction: column;
}

.Notes .list {
  display: flex;
  flex-direction: column;
}

.Notes .scroll {
  min-height: 200px;
  max-height: 400px;
  overflow: scroll;
  padding: 15px 15px;
}

.Notes .default {
  padding: 20px;
  font-size: 12px;
}
