.OwnerEntry {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDD;
  padding-right: 20px;
  padding: 10px 20px 10px 0;
  cursor: pointer;
  text-decoration: none;
}

.OwnerEntry:hover {
  background-color: #f5f5f5;
}

.OwnerEntry.inactive:hover {
  background-color: transparent;
}

.OwnerEntry:last-child {
  border-bottom: none;
}

.OwnerEntry .iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  padding: 10px 0;
}

.OwnerEntry .entryBody {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.OwnerEntry .entryBody .actions {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.OwnerEntry .entryBody .isp-status {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 20px;
}

.OwnerEntry .entryBody .isp-status .fbEntry, .OwnerEntry .entryBody .isp-status .crmEntry {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.OwnerEntry .entryBody .isp-status .crmEntry h4 {
  font-size: 12px;
  margin-right: 5px;
}

.OwnerEntry h2 {
  color: #999;
  font-weight: 300;
  margin: 0;
  font-size: 18px;
}

.OwnerEntry h4 {
  color: #999;
  font-weight: 300;
  margin: 0;
  font-size: 13px;
}

.OwnerEntry h2 > span, .OwnerEntry h4 > span {
  color: black;
}

.OwnerEntry .nameTitle {
  display: flex;
  align-items: center;
}

.OwnerEntry .nameTitle > span:last-child {
  color: #999;
}

.OwnerEntry .nameTitle > span {
  maxWidth: 50%;
  display: block;
}

.OwnerEntry .detailsWrap {
  margin-top: 10px;
  display: flex;
}

.OwnerEntry .detail {
  display: flex;
  align-items: center;
  margin-right: 10px;
  /* width: 80px; */
}

.OwnerEntry .detail > h4 {
  margin-left: 5px;
}

.OwnerEntry .paidStatus, .OwnerEntry .unpaidStatus {
  font-weight: 400;
}

.OwnerEntry .paidStatus {
  color: #168E32;
}

.OwnerEntry .unpaidStatus {
  color: #999;
}

.OwnerEntry .routes {
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px;
}

.OwnerEntry .match {
  border-bottom: 3px solid #F9EF75;
  margin-top: 3px;
}

.OwnerEntry .owner {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.OwnerEntry .owner > h4 {
  margin-left: 5px;
}
