.CrashPlaceHolder {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CrashPlaceHolder .ErrorMessage {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0;
  padding: 0 20px;
}

.CrashPlaceHolder .ErrorMessage > h1,
.CrashPlaceHolder .ErrorMessage > h3,
.CrashPlaceHolder .ErrorMessage > h4,
.CrashPlaceHolder .ErrorMessage > p {
  text-align: center;
  padding-top: 10px;
}
