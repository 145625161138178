.UploadTarget {
  padding: 20px;
}

.UploadTarget .file-target {
  height: 100%;
  border: 4px dashed #DDD;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.UploadTarget .file-target h2 {
  color: #999;
  margin: 0;
}

.UploadTarget .file-target .upload-icon {
  display: block;
  text-align: center;
  font-size: 70px;
  color: #DDD;
}

.UploadTarget .file-target .Button {
  margin-top: 20px;
}

.UploadTarget .file-target .hovering .upload-icon {
  color: #006DAB;
  font-size: 100px;
}
