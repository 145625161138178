.GhostAccounts > .Section {
  margin-bottom: 20px;
}

.GhostAccounts .Account h2 {
  font-weight: 200;
  font-size: 18px;
  margin: 0;
  padding: 20px 10px;
  color: #777;
}

.GhostAccounts .Account h2 strong {
  font-weight: 400;
  color: #B90C0F;
}

.GhostAccounts .Operation {
  padding: 10px;
  border-top: 1px solid #ddd;
}

.GhostAccounts .Operation:hover {
  background-color: #f5f5f5;
}

.GhostAccounts .Operation h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  flex: 1;
}

.GhostAccounts .Operation {
  display: flex;
  align-items: center;
}

.GhostAccounts .Operation .closeIconWrap {
  cursor: pointer;
  padding: 2px;
  color: #B90C0F;
}