.DashboardSummary {
  display: flex;
  flex-direction: column;
  height: 500px;
}

.DashboardSummary .top {
  display: flex;
  height: 60px;
  border-bottom: 1px solid #DDD;
  align-items: center;
  padding: 5px 20px;
}

.DashboardSummary .option {
  height: 35px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid #DDD;
  border-radius: 3px;
  padding: 8px 15px;
}

.DashboardSummary .option:hover {
  cursor: pointer;
}

.DashboardSummary .option.active {
  background-color: rgb(0, 109, 171);
  border-color: rgb(0, 109, 171);
}

.DashboardSummary .option.active p {
  color: white;
}

.DashboardSummary .header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-right: 1px solid #DDD;
  font-weight: 400;
}

.DashboardSummary .averages {
  padding: 15px;
  display: flex;
  align-items: center;
  flex: 3;
}

.DashboardSummary .averages p {
  margin-right: 40px;
}

.DashboardSummary .bottom {
  display: flex;
  flex: 1;
}


.DashboardSummary .leftSide {
  position: relative;
  padding: 35px;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.DashboardSummary .leftSide .label {
  position: absolute;
  top: 10px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.DashboardSummary .rightSide {
  flex: 3;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
}

.DashboardSummary .leftSideRow {
  display: flex;
  flex: 1;
  align-items: center;
}

.DashboardSummary .leftSideRow i {
  margin-right: 25px;
}

.DashboardSummary h4 {
  margin: 0;
  font-weight: 300;
  font-size: 19px;
  color: #999;
  margin: 3px 0;
}

.DashboardSummary h4 span {
  color: black;
}

.DashboardSummary .metric {
  height: 25%;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
}

.DashboardSummary .metric:last-child {
  border-bottom: none;
}

.DashboardSummary .stats {
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;
}

.DashboardSummary .chartWrap {
  height: 100%;
  width: 60%;
}

.DashboardSummary .labelBar {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
}

.DashboardSummary .labelBar p {
  font-size: 14px;
}

.DashboardSummary .statsBar {
  flex: 1;
  display: flex;
  align-items: center;
}

.DashboardSummary .statsBar p {
  font-size: 12px;
}

.DashboardSummary .statWrap {
  flex: 1;
  display: flex;
}
