.Category .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Category .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.Knowledge .knowledge-section .Category.card {
  padding: 0;
}

.Category .btn:hover {
  cursor: pointer;
}

.Category .ArticleCard {
  font-size: 14px;
}
