TouchableHighlight {
  height: fit-content;
  width: fit-content;
  opacity: 1;
}

TouchableHighlight.pressed {
  opacity: .6;
  transition: .4s;
}

TouchableHighlight:hover {
  opacity: .8;
  transition: .2s;
}

TouchableHighlight:active {
  opacity: .6;
  transition: .4s;
}