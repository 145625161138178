.ISPModal {
  background-color: white;
  z-index: 25;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  overflow: scroll;
}

.ISPModal .top {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ISPModal .name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ISPModal .top .name p {
  font-weight: 500;
}

.ISPModal .top .name span {
  color: #999;
  font-size: 14px;
}

.ISPModal .name i {
  margin: 0;
}

.ISPModal .top .close:hover {
  cursor: pointer;
}

.ISPModal .section {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
}

.ISPModal .info {
  flex: 1;
  position: relative;
}

.ISPModal .scrollable {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ISPModal .options {
  padding: 10px;
}

.ISPModal .options-header {
  border-bottom: 1px solid #DDD;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ISPModal .options-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.ISPModal .action {
  padding: 10px 0;
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.ISPModal .action:hover {
  background-color: #DDD;
  cursor: pointer;
}

.ISPModal i {
  margin-right: 10px;
}

.ISPModal .action p {
  font-size: 12px;
  font-weight: 700;
}

.ISPModal .info-section {
  padding: 10px;
  margin: 0 5px;
  display: block;
}

.ISPModal .info-section p {
  font-size: 14px;
}


.ISPModal .info-header {
  border-bottom: 1px solid #DDD;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.ISPModal .info-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.ISPModal .info-bit {
  display: flex;
  align-items: flex-start;
  padding: 5px 0;
}

.ISPModal .tag-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.ISPModal .tag {
  background-color: #E8E8E8;
  border-radius: 20px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-bottom: 5px;
}

.ISPModal .tag:hover {
  cursor: pointer;
}

.ISPModal .tag p {
  font-size: 14px;
}

.ISPModal .notes-wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.ISPModal .assoc-btn, 
.NewISP .assoc-btn2,
.ISPModal .assoc-btn2 {
  border-radius: 5px;
  padding: 5px 15px;
}

.ISPModal .assoc-btn {
  background-color: rgb(22, 142, 50);
}

.ISPModal .assoc-btn2 {
  background-color: #f5f5f5;
  margin: 0 5px;
  border: 1px solid #DDD;
}

.ISPModal .clickable:hover {
  cursor: pointer;
}

.ISPModal .action {
  color: black;
  font-weight: 700;
  font-size: 12px;
}

.ISPModal a.action {
  text-decoration: none;
  color: black;
}

.ISPModal .actions .action:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.ISPModal .link-wrapper {
  margin: 0 20px;
}

@media screen and (min-width: 768px) {
  .ISPModal .section {
    flex-direction: row;
  }
}
