.SalesPipeline {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.SalesPipeline .filters {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 10px;
  justify-content: flex-end;
  overflow-x: scroll;
}

.SalesPipeline .filters-summary {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 10px;
}

.SalesPipeline .filters .filter-section {
  background-color: white;
  display: flex;
  margin: 0 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.SalesPipeline .filter {
  background-color: white;
  padding: 10px;
  border-left: 1px solid #ddd;
}

.SalesPipeline .filter:first-child {
  border: none;
}

.SalesPipeline .filter.off {
  background-color: #fcfcfc;
}

.SalesPipeline .filter:hover {
  cursor: pointer;
  background-color: #DDD;
}

.SalesPipeline .filter.off p {
  color: #999;
}

.SalesPipeline h1 {
  margin: 0;
  font-size: 24px;
  color: #999;
  font-weight: 300;
}

.SalesPipeline .lists {
  flex: 1;
  display: flex;
  overflow-x: scroll;
}
