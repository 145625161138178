.Business {
  display: flex;
  flex: 1;
  margin: 0;
  flex-direction: column;
  /* padding-bottom: 40px; */
}

.Business .section {
  display: flex;
  flex: 1;
}

.Business.expand {
  padding-bottom: 0px;
}

.Business .column {
  display: flex;
  flex-direction: column;
}

.Business .data {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.Business .misc {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #DDD;
  padding: 7px;
  width: 75%;
}

.Business .misc:first-of-type {
  border-top: 1px solid #DDD;
}

.Business .misc span {
  font-weight: 500;
}

.Business .misc p {
  font-size: 12px;
}

.Business .data h4 {
  font-weight: 300;
}

.Business .data .headerText {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Business .data .headerText p {
  font-size: 48px;
}

.Business .referred {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
}

.Business .route {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.Business .route h5 {
  margin: 0;
}

.Business .route p {
  font-size: 20px;
  margin: 0;
  margin-top: 5px;
}

.Business .status {
  margin-bottom: 20px;
}

.Business .status h3 {
  font-size: 0.85em;
  font-weight: 300;
  margin: 0;
  margin-bottom: 5px;
}

.Business .input-container.status-checkbox {
  margin-bottom: 5px;
  display: flex;
  flexDirection: row;
  alignItems: center;
}

.Business .input-container.status-checkbox span {
  font-size: 12px;
}

.Business .input-container .timestamp {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Business .box {
  border: 1px solid #DDD;
  border-radius: 4px;
  padding: 5px;
}

.Business .qualified {
  display: flex;
  flex: 1;
}
