.Note {
  padding: 10px;
}

.Note .details {
  display: flex;
  align-items: center;
}

.Note .text {
  border-left: 4px solid #DDD;
  padding: 15px;
  word-wrap: break-word;
}

.Note .AddNote {
  border: none;
}

.Note .AddNote {
  border-radius: 5px;
  background-color: #f5f5f5;
}

.Note .AddNote input {
  border-radius: 5px;
  background-color: #f5f5f5;
}

.Note .edit {
  margin-left: 5px;
}

.Note .edit:hover {
  cursor: pointer;
}

.Note .time p, .Notes .user p {
  font-size: 12px;
  font-weight: 300;
  padding: 5px 0;
}

.Note .user p {
  font-weight: 500;
}

.Note .user {
  margin-right: 5px;
}
