.UserIsp {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  flex-direction: column;
  box-sizing: border-box;
}

.UserIsp .top:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.UserIsp .top {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px 10px 10px 10px;
  min-height: 50px;
}

.UserIsp .activeLightWrap {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UserIsp .activeLight {
  background-color: #DDD;
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.UserIsp .activeLight.active {
  background-color: #168E32;
}

.UserIsp .identity {
  display: flex;
  align-items: center;
}

.UserIsp .misc {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.UserIsp:last-child {
  border-bottom: none;
}

.UserIsp .iconWrap {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UserIsp .waWrap {
  padding: 5px 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #DDD;
}

.UserIsp .waWrap p {
  color: black;
}

.UserIsp .userName {
  margin: 0;
  font-weight: 300;
  flex: 1;
  font-size: 16px;
}

.UserIsp > .mdi:last-child {
  margin-left: 10px;
}

.UserIsp .bcLabel, .UserIsp .driverLabel {
  font-size: 12px;
}

.UserIsp .bcLabel {
  color: #006DAB;
  font-weight: 400;
}

.UserIsp .driverLabel {
  color: #FF9200;
  font-weight: 400;
}


.UserIsp .actions {
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f5f5f5;
}

.UserIsp .actions .option {
  padding: 5px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
}

.UserIsp .userId {
  color: #999;
  margin: 3px 0 0 0;
}

@media screen and (min-width: 768px) {
  .UserIsp .nameWrap {
    display: flex;
  }
}
