.Station .notFound i.mdi {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.Station .notFound h1 {
  font-weight: 300;
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
  color: #999;
}

.Station .notFound h1 span {
  color: black;
}

.Station .stationDetails {
  padding: 20px;
  display: flex;
  align-items: center;
}

.Station .detailsBody {
  flex: 1;
  margin-left: 20px;
  color: #999;
}

.Station .detailsName {
  margin: 0;
  font-weight: 300;
  font-size: 22px;
  margin-bottom: 5px;
  color: black;
}

.Station .editButton {
  color: #B90C0F;
  font-weight: bold;
  font-size: 12px;
  text-align: right;
  cursor: pointer;
}

.Station .timeline {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px;
}

.Station .timeline-tick {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.Station .timeline-tick h5 {
  margin: 0;
  font-size: 20px;
  font-weight: 300;
}

.Station .timeline-tick > div {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 5px;
}

.Station .actions {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 300px;
}

.Station .option {
  padding: 5px;
  display: flex;
  align-items: center;
}

.Station .actions p {
  font-weight: 700;
  font-size: 12px;
}

.Station .actions .option:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.Station .UserAdvocate {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.Station .UserAdvocate:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.Station .UserAdvocate .title {
  display: flex;
  align-items: center;
  margin: 0;
}

.Station .UserAdvocate .iconWrap {
  margin-right: 10px;
}
