.ArticleCard {
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
}

.ArticleCard.clickable:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
