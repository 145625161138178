.SwiperWrap {
  position: relative;
  height: fit-content;
  width: fit-content;
}

.SwiperOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, .8);
}
