.Button {
  color: white;
  font-weight: 300;
  display: block;
  border-radius: 3px;
  border: none;
  font-size: 0.9em;
  text-align: center;
  padding: 7px 15px;
  cursor: pointer;
  margin: 0 auto;
}

.Button:focus {
  outline: none;
}

.Button:hover {
  cursor: grab;
}