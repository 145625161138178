.Transaction {
  display: flex;
}

.Transaction.otherSubscription {
  background-color: #f5f5f5;
}


.Transaction:not(:first-child) {
  border-top: 1px solid #DDD;
}

.Transaction .id, .Transaction .timestamp {
  color: #999;
  font-size: 12px;
  text-decoration: none;
}

.Transaction .failureMessage {
  margin-left: 20px;
  color: #B90C0F;
}

.Transaction .body {
  flex: 1;
  padding: 5px 10px;
}

.Transaction .description {
  margin: 5px 0;
  text-transform: uppercase;
}

.Transaction .timestamp .exactDate, .Transaction .timestamp:hover .timeFrom {
  display: none;
}

.Transaction .timestamp:hover .exactDate {
  display: block;
}

.Transaction .valueWrap {
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.Transaction .valueWrap .value.negative {
  color: #B90C0F;
}

.Transaction .valueWrap .value.sub {
  color: #999;
}

.Transaction .valueWrap .value.net {
  color: #168E32;
}

.Transaction .currentOp {
  background-color: #B90C0F;
  padding: 2px 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.Transaction .currentOp p {
  color: white;
  margin-left: 5px;
  font-weight: 400;
  font-size: 12px;
}