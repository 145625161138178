.StationLink {
  padding: 8px;
  color: black;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.StationLink .descriptive, .StationLink a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.StationLink.clickable:hover {
  color: white;
  background-color: #006DAB;
  cursor: pointer;
}

.StationLink.clickable:hover .icon i {
  color: white !important;
}

.StationLink .icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.StationLink .icon i {
  color: #006DAB !important;
}

.StationLink.clickable:hover p {
  color: white;
}

.StationLink p {
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 14px;
}
