.ISPItem {
  background-color: #f5f5f5;
  margin: 5px;
  min-height: 60px;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ISPItem.full {
  margin: 0;
  border-radius: 0;
  box-sizing: border-box;
  border-bottom: 1px solid #DDD;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ISPItem.full:last-of-type {
  border-bottom: 0px solid #DDD;
}

.ISPItem.white {
  background-color: white
}

.ISPItem:hover {
  cursor: pointer;
}

.ISPItem.white:hover {
  background-color: white;
}

.ISPItem .section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.ISPItem .isp-name {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 80%;
  flex-direction: column;
}

.ISPItem .isp-name p span {
  margin-left: 5px;
  color: #999;
  font-size: 10px;
}

.ISPItem .isp-name p {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
}

.ISPItem .isp-name span {
  color: #666;
  font-size: 12px;
  margin-left: 5px;
}

.ISPItem .isp-status {
  display: flex;
  align-items: center;
}

.ISPItem .isp-status p {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
}

.ISPItem .account-value p {
  font-size: 12px;
  color: rgb(22, 142, 50);
}

.ISPItem .isp-activity {
  display: flex;
  align-items: center;
}

.ISPItem .isp-activity p {
  font-size: 10px;
  margin: 0 2px;
}
