.NavItem {
  padding: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavLink {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.NavLink .callout {
  position: absolute;
  display: none;
  left: 100%;
  width: 120px;
  padding: 10px;
  background-color: #006DAB;
  color: white;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.NavLink:hover .callout {
  display: block;
}
