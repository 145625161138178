.Knowledge {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.Knowledge .knowledge-section {
  padding: 20px;
}

.Knowledge .knowledge-section .list {
  width: 100%;
  background-color: white;
  border: 1px solid #DDD;
  border-radius: 3px;
}

.Knowledge .knowledge-section .header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DDD;
}

.Knowledge .knowledge-section .list .header .new form {
  display: flex;
}

.Knowledge .knowledge-section .list .header .new form .Button {
  margin-left: 15px;
}

.Knowledge .knowledge-section .card {
  border-bottom: 1px solid #DDD;
  padding: 5px 10px;
}

.Knowledge .knowledge-section .card:nth-child(even) {
  background-color: #FCFCFC;
}

.Knowledge .knowledge-section .card.highlight {
  border: 1px solid yellow;
  background-color: yellow;
  opacity: 0.25;
}

.Knowledge .knowledge-section .card:last-child {
  border-bottom: 0;
}

.Knowledge .error {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #DDD;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.Knowledge .error p {
  color: red;
  font-size: 16px;
}
