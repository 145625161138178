.PageTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.PageTitle > div {
  display: flex;
  align-items: center;
}

.PageTitle h1 {
  font-weight: 300;
  font-size: 24px;
  color: #444;
  margin: 10px 0 10px 15px;
}

.PageTitle h1:last-child {
  color: #999;
}
