.SubscriptionsPage .isLiveBanner {
  text-align: center;
  color: white;
  padding: 8px;
  font-weight: 500;
  letter-spacing: 1px;
}

.SubscriptionsPage .isLiveBanner.live {
  background-color: #168E32;
}

.SubscriptionsPage .isLiveBanner.test {
  background-color: #EA7D14;
}

.SubscriptionsPage .Balance {
  display: flex;
  align-items: center;
}

.SubscriptionsPage .Balance:not(:first-child) {
  border-top: 1px solid #ddd;
}

.SubscriptionsPage .Balance .body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.SubscriptionsPage .Balance .pending {
  color: #B90C0F;
  margin: 0 20px;
  font-size: 14px;
  font-weight: 500;
}

.SubscriptionsPage .Balance .amount {
  margin-right: 10px;
  font-size: 22px;
}

.SubscriptionsPage .fullSectionLink {
  text-align: center;
  display: block;
  padding: 8px 0;
}