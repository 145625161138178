.Section {
  margin-bottom: 30px;
}

.Section > .body {
  background-color: white;
  border: 1px solid #DDD;
}

.Section .title {
  font-weight: 300;
  font-size: 20px;
  color: #444;
  margin: 0 0 10px 10px;
  flex: 1;
  white-space: nowrap;
}

.Section .title .subtitle {
  font-size: 12px;
}

.Section > .top {
  display: flex;
  align-items: center;
}

.Section .reloadContent {
  cursor: pointer;
  color: #999;
  display: flex;
  align-items: center;
}

.Section .reloadContent .reloadStamp {
  margin-right: 10px;
}