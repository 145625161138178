.TouchableOpacity {
  height: fit-content;
  width: fit-content;
  opacity: 1;
  display: flex;
}

.TouchableOpacity.clickable {
  cursor: pointer;
}

.TouchableOpacity.pressed {
  opacity: .6;
  transition: .4s;
}

.TouchableOpacity:hover {
  opacity: .8;
  transition: .2s;
}

.TouchableOpacity:active {
  opacity: .6;
  transition: .4s;
}