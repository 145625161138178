.CoLocation {
  display: flex;
  align-items: center;
}

.CoLocation .label {
  padding: 0 10px;
}

.CoLocation .label p {
  font-size: 14px;
}

.CoLocation .locked {
  opacity: 0.8;
}

.CoLocation .locked:hover {
  opacity: 1;
  cursor: pointer;
}

.CoLocation .text {
  text-decoration: line-through;
  color: #999;
  font-size: 14px;
  margin-left: 5px;
  background-color: #CCC;
  padding: 5px;
  border-radius: 3px;
}

.CoLocation .text.active {
  text-decoration: none;
  background-color: #EA7D14;
  color: white;
}

.CoLocation .text.unlocked:hover {
  background-color: #BBB;
  cursor: pointer;
}

.CoLocation .text.active.unlocked:hover {
  background-color: #13782A;
}

.CoLocation .input-wrapper {
  padding: 10px;
  width: 100px;
  height: 25px;
  display: flex;
  align-items: center;
}
