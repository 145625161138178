.StationLists {
  display: flex;
}

.StationLists .list-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.StationLists .list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #DDD;
}

.StationLists .list-header h4 {
  font-weight: 400;
}

.StationLists .list-header:first-child {
  border-right: 1px solid #DDD;
}

.StationLists .station-info {
  flex: 1;
  display: flex;
  align-items: center;
}

.StationLists .station-info p {
  margin-left: 5px;
}

.StationLists .station-item {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #DDD;
}

.StationLists .station-item:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.StationLists .HDOnly, .StationLists .StationAdvocate {
  margin-right: 0;
}
