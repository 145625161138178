#page {
  min-height: fit-content;
  height: 100%;
  display: flex;
  background: #f5f5f5;
  flex-direction: column;
}

#page-body {
  flex: 1;
  overflow: auto;
  position: relative;
}

@media screen and (min-width: 768px) {
  #page {
    flex-direction: row;
  }
}
