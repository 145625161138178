.TagDueDate .due-date {
  background-color: #DDD;
  border-radius: 5px;
  padding: 1px 5px;
  display: flex;
  align-items: center;
}

.TagDueDate .due-date:hover {
  background-color: #999;
}

.TagDueDate .due-date p {
  color: black;
  font-size: 9px;
  font-weight: 300;
  margin-left: 5px;
}

.TagDueDate .warning {
  height: 8px;
  width: 8px;
  border-radius: 4px;
}
