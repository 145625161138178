.StationsSummary {
  /* position: relative; */
  height: 450px;
  display: flex;
  flex-direction: column;
}

.StationsSummary .top {
  height: 70px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #DDD;
}

.StationsSummary .body {
  height: 380px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.StationsSummary .list {
  flex: 2;
  display: flex;
}

.StationsSummary .map {
  flex: 5;
  display: flex;
}

.StationsSummary .left, .StationsSummary .right {
  display: flex;
}

.StationsSummary .left {
  align-items: center;
  justify-content: center;
  border-right: 1px solid #DDD;
  flex: 1;
}

.StationsSummary .right {
  display: flex;
  flex: 3;
}

.StationsSummary .fraction {
  font-weight: 300;
  font-size: 24px;
  margin: 0;
}

.StationsSummary .StationShort {
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  font-size: 18px;
  text-decoration: none;
  flex: 1;
}

.StationsSummary .StationShort div {
  display: flex;
  flex: 1;
}

.StationsSummary .StationShort p {
  margin-right: 40px;
}

.StationsSummary .StationShort:hover {
  cursor: pointer;
  background-color: #DDD;
}
