.SalesListOptions {
  position: absolute;
  top: 35px;
  right: 10px;
  width: 200px;
  min-height: 200px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #DDD;
  z-index: 120;
}

.SalesListOptions h3 {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
}

.SalesListOptions .title {
  border-bottom: 1px solid #DDD;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  flex: 1;
}

.SalesListOptions .list {
  flex: 1;
}

.SalesListOptions .list .option {
  padding: 5px;
}

.SalesListOptions .list .option:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.SalesListOptions .list .option p {
  font-weight: 700;
  font-size: 14px;
}
