.OnOff-btn {
  display: flex;
  flex-direction: row;
  padding: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.OnOff-btn .btn {
  padding: 10px 20px;
  border-radius: 5px;
}

.OnOff-btn .btn.off-btn.active {
  background-color: rgb(185, 12, 15);
}

.OnOff-btn .btn.on-btn.active {
  background-color: rgb(22, 142, 50);
}

.OnOff-btn .btn p {
  font-weight: 500;
}

.OnOff-btn .btn.hover:hover {
  cursor: pointer;
  opacity: 0.75;
  background-color: #ddd;
}

.OnOff-btn .btn.active p {
  color: white;
}
