.IconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 2px;
}

.IconButton:hover {
  cursor: grab;
}

.HoverIconClickaway {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
}

.HoverIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

.HoverIcon .hoverContent {
  position: absolute;
  padding: 10px;
  border-radius: 5px;
}

.TooltipIcon.markdown h1, 
.TooltipIcon.markdown h2, 
.TooltipIcon.markdown h3, 
.TooltipIcon.markdown h4, 
.TooltipIcon.markdown h5, 
.TooltipIcon.markdown h6, 
.TooltipIcon.markdown p,
.TooltipIcon.markdown span {
  color: white;
}

.TooltipIcon.markdown h1 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.TooltipIcon.markdown h2 {
  font-size: 16px;
  margin-bottom: 3px;
}

.TooltipIcon.markdown p {
  font-size: 14px;
  margin-bottom: 10px;
}

.TooltipIcon.markdown :last-child {
  margin-bottom: 5px;
}