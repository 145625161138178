.change-btn {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: rgb(22, 142, 50);
  border-radius: 5px;
}

.change-btn p {
  font-weight: 500;
  color: white;
}

.change-btn:hover {
  cursor: pointer;
  opacity: 0.75;
}
