.CurrencyIcon {
    width: 50px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-right: 1px solid #EEE;
    font-size: 10px;
  }
  
  .CurrencyIcon i {
    font-size: 18px;
    color: #168E32;
  }