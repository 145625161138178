.InputSwitch {
  display: flex;
  height: 100%;
  width: 100%;
}

.InputSwitch .switch {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  opacity: 0.8;
}

.InputSwitch .switch.enabled {
  opacity: 1.0;
}

.InputSwitch .switch p {
  color: #999;
  font-size: 14px;
}

.InputSwitch .switch:hover {
  background-color: #DDD;
  cursor: pointer;
}

.InputSwitch .switch.on {
  border: 1px solid #DDD;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.InputSwitch .switch.off {
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  border-right: 1px solid #DDD;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.InputSwitch .on.enabled {
  background-color: #168e32;
  border-color: #168e32;
}

.InputSwitch .off.enabled {
  background-color: rgb(185, 12, 15);
  border-color: rgb(185, 12, 15);
}

.InputSwitch .switch.enabled:hover {
  opacity: 0.8;
}

.InputSwitch .enabled p {
  color: white;
}

.InputSwitch .switch.on.locked,
.InputSwitch .switch.off.locked {
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  border-right: 1px solid #DDD;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.InputSwitch .switch.locked:hover {
  opacity: 0.8;
  cursor: default;
}

.InputSwitch .switch.locked:hover {
  background-color: #f5f5f5;
}

.InputSwitch .switch.on.enabled.locked:hover {
  background-color: rgb(22, 142, 50);
  opacity: 1.0;
}

.InputSwitch .switch.off.enabled.locked:hover {
  background-color: rgb(185, 12, 15);
  opacity: 1.0;
}
