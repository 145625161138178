.container {
    width: 100%;
    max-width: 3000px;
    margin: 0 auto;
    padding: 24px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;
}

.filterRow {
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
}

.input[type='text'],
.input[type='number'],
select {
    margin-right: 8px;
    padding: 6px 12px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    font-size: 14px;
    outline: none;
}

.input[type='text']:focus,
.input[type='number']:focus,
select:focus {
    border-color: rgba(0, 0, 0, 0.87);
}

.button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    background-color: #3f51b5;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.button:hover {
    background-color: #2c387e;
}

.tableContainer {
    overflow-x: auto;
    margin-top: 16px;
}

.table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
}

.table th,
.table td {
    padding: 14px 24px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.table th {
    font-weight: 500;
    background-color: rgba(250, 250, 250, 1);
    color: rgba(33, 33, 33, 1);
    cursor: pointer;
}

.table th:hover {
    background-color: rgba(238, 238, 238, 1);
}

.table td {
    color: rgba(33, 33, 33, 1);
}

.table tr {
    background-color: rgba(255, 255, 255, 1);
}

.table tr:hover {
    background-color: rgba(245, 245, 245, 1);
}

.link {
    color: rgba(33, 150, 243, 1);
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 1.2rem;
    color: rgba(33, 33, 33, 1);
}

.addFilterButton,
.submitButton {
    margin-right: 16px;
}

.filtersWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Update this line to align the filters container with the buttons container */
}


.filtersContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.buttonsContainer {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.filterField {
    margin-right: 8px;
    padding: 6px 12px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    background-color: #fff;
}

.filterField:focus {
    border-color: rgba(0, 0, 0, 0.87);
}

