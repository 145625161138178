.NativeModal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.NativeModal .modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.6);
}

.NativeModalWrap {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NativeModalWrap .NativeModal {
  z-index: 21;
  pointer-events: none;
}

.NativeModalWrap .NativeModal * {
  pointer-events: all;
}

.ModalClickAway {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgba(120, 120, 120, .5);
}

.NativeModalWrap > .PortalModal {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 25;
  margin: auto;
  pointer-events: none;
}

.NativeModalWrap > .PortalModal > div {
  display: flex;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  background-color: none;
  pointer-events: all;
}

.PortalModal.mobile {
  width: 100vw;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  margin: 0;
  max-height: none;
  max-width: none;
  border-radius: 0;
}

.PortalModal.show,
.ModalClickAway.show {
  animation: showOverlay .3s forwards;
  animation-fill-mode: forwards;
}

.PortalModal.hide,
.ModalClickAway.hide {
  animation: hideOverlay .5s forwards;
  display: none;
}

@keyframes showOverlay {
  0% { 
    display: none;
    opacity: 0;
  }
  99% { 
    display: block;
  }
  100% { opacity: 1; }
}

@keyframes hideOverlay {
  0% { opacity: 1; }
  99% { opacity: 0; }
  100% { display: none; }
}

