.calendar-wrap {

}

.select-wrap .label,
.calendar-wrap .label {
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 0.85em;
  color: #666;
  display: flex;
  align-items: center;
}