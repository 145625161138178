.Spinner {
  border: 6px solid rgba(0, 0, 0, 0);
  border-top: 6px solid #DDD; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
