.IspSummary {
  height: 300px;
  display: flex;
}

.IspSummary .left, .IspSummary .middle, .IspSummary .right {
  width: 33.33%;
  height: 100%;
}

.IspSummary .left {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #DDD;
  flex-direction: column;
  position: relative;
}

.IspSummary .middle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.IspSummary .right {
  border-left: 1px solid #DDD;
}

.IspSummary .fraction {
  font-weight: 300;
  font-size: 24px;
  margin: 0;
  border-bottom: 1px solid #DDD;
  padding: 0 30px 15px;
  margin-bottom: 15px;
}

.IspSummary .fractionTitle {
  margin: 0;
  color: #999;
}

.IspSummary .fractionPercent {
  position: absolute;
  bottom: 0;
  color: #999;
}

.IspSummary .middle .noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #999;
}

.IspSummary .history {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.IspSummary .history-title {
  padding: 0 0 10px 0;
  border-bottom: 1px solid #DDD;
  margin-bottom: 10px;
}

.IspSummary .history-table {
  flex: 1;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  /* border: 1px solid #DDD; */
  /* background-color: #f5f5f5; */
  padding: 10px;
  border-radius: 5px;
}

.IspSummary .history-row {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 5px 0;
}

.IspSummary .history-header {
  min-width: 55px;
}

.IspSummary .history-cell {
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
}

.IspSummary .circle {
  background-color: #168E32;
  height: 8px;
  width: 8px;
  border-radius: 4px;
}

.IspSummary .history-summary {
  display: flex;
  align-items: center;
  justify-content: center;
}

.IspSummary .history-summary .summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 0;
}

.IspSummary .history-summary .summary p {
  font-size: 12px;
}

.IspSummary .history-summary .summary p:first-child {
  border-bottom: 2px solid #DDD;
  padding-bottom: 2px;
  margin-bottom: 2px;
}
