/* .ServiceStatus {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
} */

.ServiceStatus .actions-wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.ServiceStatus .border-line {
  width: 100%;
  border-bottom: solid 1px #ddd;
}