.Assets {
  min-height: 100vh;
}

.Assets .header {
  padding: 10px;
}

.Assets .content {
  flex: 1;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  flex-wrap: wrap;
  margin: 10px;
  border: 1px solid #ddd;
}

.Assets .img-wrapper {
  max-width: 200px;
  margin: 10px;
  border-radius: 3px;
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Assets .img-wrapper img {
  width: 100%;
}

.Assets .img-wrapper:hover {
  opacity: 0.9;
  cursor: pointer;
}

.Assets .active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  display: flex;
  align-items: center;
}

.Assets .active .active-content {
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Assets .active .active-content .top {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.Assets .active .active-content .img-info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 10px 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
}

.Assets .active-img {
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
}

.Assets .active-img img {
  width: 100%;
  border: 1px solid #ddd;
}

.Assets .header {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}

.Assets .active .close:hover {
  cursor: pointer;
}

.Assets .active .copy:hover {
  cursor: pointer;
}
