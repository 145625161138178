.TransferOwnershipModal {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  max-height: 1200px;
  min-width: 800px;
  max-width: 1400px;
  z-index: 25;
  justify-content: space-between;
  border-radius: 5px;
  position: relative;
  padding: 10px;
}

.TransferOwnershipModal .title-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60;
  margin-bottom: 10px;
}

.TransferOwnershipModal .title-wrap .title {
  font-size: 20px;
}

.TransferOwnershipModal .title-wrap .error-message {
  font-size: 14px;
}

.TransferOwnershipModal .content-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

.TransferOwnershipModal .status-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TransferOwnershipModal .TransferChangesTitle {
  margin-top: 10px;
}

.TransferOwnershipModal .TransferChanges li {
  margin-bottom: 10px;
}