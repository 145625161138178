.Referee {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}

.Referee:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.Referee .info {
  display: flex;
  align-items: center;
}

.Referee .status {
  display: flex;
  align-items: center;
}

.Referee .status .referrer {
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
}

.Referee .status .referrer:hover {
  cursor: pointer;
  background-color: #ddd;
}

.Referee .status .referrer.advocate {
  background-color: rgb(185, 12, 15)
}

.Referee .status .referrer.advocate p {
  color: white;
}

.Referee .status .referrer.advocate:hover {
  background-color: rgb(200, 12, 15);
}
