.Isp .notFound i.mdi {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.Isp .notFound h1, .Isp .noAssoc h1 {
  font-weight: 300;
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
  color: #999;
}

.Isp .notFound h1 span {
  color: black;
}

.Isp .ispDetails {
  padding: 20px;
  display: flex;
  align-items: center;
}

.Isp .detailsBody {
  flex: 1;
  margin-left: 15px;
  color: #999;
}

.Isp .address {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 5px;
  color: black;
  text-transform: uppercase;
}


.Isp .related {
  text-align: center;
  padding: 30px 0;
  color: #999;
}

.Isp .stripeLinkWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}