.LifecycleMetrics {
  height: 175px;
  display: flex;
  border-bottom: 1px solid #DDD;
}

.LifecycleMetrics .metric {
  flex: 1;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #DDD;
}

.LifecycleMetrics .metric:last-child {
  border-right: none;
}

.LifecycleMetrics .metric-title {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
}

.LifecycleMetrics .number {
  font-size: 30px;
  font-weight: 400;
  padding: 10px;
  margin: 10px;
}

.LifecycleMetrics .scope {
  font-size: 10px;
  font-style: italic;
}
