.Contacts {
  display: flex;
  flex: 1;
  margin: 0;
  flex-direction: column;
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  padding: 5px 0;
  padding-bottom: 40px;
}

.Contacts .header {
  display: flex;
  align-items: center;
}

.Contacts .header span {
  color: #7A7A7A;
}

.Contacts .section.container {
  min-height: 200px;
}

.Contacts .default {
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}

.Contacts .default p {
  font-size: 20px;
  font-weight: 300;
}

.Contacts .section {
  display: flex;
  flex: 1;
}

.Contacts .column {
  display: flex;
  flex-direction: column;
}

.Contacts .section.info {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.Contacts .section.info h4 {
  font-weight: 300;
  border-bottom: 1px solid #DDD;
  align-self: center;
  font-size: 1.1em;
  margin-bottom: 15px;
}

.Contacts .section.info:first-child {
  border-right: 1px solid #DDD;
}

.Contacts .section .data:first-of-type {
  border-top: 1px solid #DDD;
}

.Contacts .section .data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #DDD;
}


.Contacts .section .data p {
  font-size: 14px;
}

.Contacts .section .data p:first-child {
  font-weight: 500;
}
