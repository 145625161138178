.CalendarList {
  flex: 1;
}

.CalendarList .header {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: #006DAB;
  font-size: 20px;
}

.CalendarList .calendar-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.CalendarList .calendar-wrapper::-webkit-scrollbar {
    width: 0.5em;
    background-color: #f5f5f5;
}

.CalendarList .calendar-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px #DDD;
}

.CalendarList .calendar-wrapper::-webkit-scrollbar-thumb {
  background-color: #DDD;
  outline: 1px solid #f5f5f5;
  border-radius: 6px;
}

.CalendarList .calendar-wrapper .calendar-date {
  display: block;
  padding: 0 10px;
  min-height: min-content;
  min-height: -moz-min-content;    /* Firefox */
  min-height: -webkit-min-content; /* Chrome */
}

.CalendarList .calendar-wrapper .calendar-date .label {
  padding-top: 5px;
  min-width: 100px;
}

.CalendarList .calendar-wrapper .calendar-date .label p {
  text-align: center;
  margin-bottom: 10px;
  color: #006DAB;
}

.CalendarList .calendar-wrapper .calendar-date .items {
  flex: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.CalendarList .calendar-wrapper .calendar-date .items .item-wrapper {
  padding: 5px 10px;
  border-top: 1px solid #DDD;
  box-sizing: border-box;
}

.CalendarList .calendar-wrapper .calendar-date .items .item-wrapper:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

/* .CalendarList .calendar-wrapper .calendar-date .items .item-wrapper.last-item {
  border-bottom: 1px solid #DDD;
} */

@media screen and (min-width: 768px) {
  .CalendarList .calendar-wrapper .calendar-date {
    display: flex;
  }

  .CalendarList .calendar-wrapper .calendar-date .label p {
    text-align: left;
  }
}