.ItemList {
  display: flex;
  flex: 1;
  padding: 10px;
}

.ItemList.column {
  flex-direction: column;
}

.ItemList.row {
  flex-direction: row;
}
