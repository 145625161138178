.Day .DayWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: 1;
  flex: 1;
  position: relative;
}

.Day .DayWrap > header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.Day .DayWrap .bodyWrap {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.Day .DayWrap .today {
  font-size: 8px;
}

.Calendar.mobile .Day .DayWrap .today {
  display: none;
}