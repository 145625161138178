.ReasonsForCancellation {
  display: flex;
  flex-direction: column;
}

.ReasonsForCancellation.defaultMessage {
  display: flex;
  height: 100px;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.ReasonsForCancellation .reasonsWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ReasonsForCancellation .reasonsWrap .reason {
  display: flex;
  height: 30px;
  width: 100%;
  text-align: left;
  align-items: center;
}

.ReasonsForCancellation .comment,
.ReasonsForCancellation .reasonsWrap .reason {
  padding: 20px;
}

.ReasonsForCancellation .comment {
  word-wrap: break-word;
  width: calc(100% - 40px);
}

.ReasonsForCancellation .comment .label {
  color: #888;
  font-size: 14px;
}

.ReasonsForCancellation .permission {
  margin-left: 20px;
  margin-bottom: 10px;
}

.ReasonsForCancellation .permission i {
  margin-left: 10px;
}