.InputSelect {
  display: flex;
  width: '100%';
  flex-direction: column;
  justify-content: center;
}

.InputSelect label, .InputSelect select {
  display: block;
}

.InputSelect label {
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 0.85em;
  color: #666;
}

.InputSelect select {
  box-sizing: border-box;
  background: transparent;
  padding: 5px;
  font-size: 1.05em;
  border-color: #DDD;
  height: 42px;
  font-weight: 300;
}

.InputSelect select:focus {
  outline: none;
}
