.UpdateISPSubscription {
  display: flex;
  flex: 1;
}

.UpdateISPSubscription .extendTrial {
  padding: 20px;
  display: flex;
  align-items: center;
  height: 75px;
}

.UpdateISPSubscription .extendTrial .extendTrialForm {
  display: flex;
  align-items: center;
}

.UpdateISPSubscription .updateButton {
  width: 200px;
  height: 42px;
  margin-left: 30px;
  background-color: white;
  border: 1px solid #006DAB;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.UpdateISPSubscription .updateButton.inactive {
  border-color: #666;
  opacity: .7;
}

.UpdateISPSubscription .updateButton p {
  color: #006DAB;
  font-weight: 400;
}

.UpdateISPSubscription .updateButton.inactive p {
  color: #666;
}

.UpdateISPSubscription .updateButton:hover, .UpdateISPSubscription .updateButton.updating {
  background-color: #006DAB;
  cursor: pointer;
}

.UpdateISPSubscription .updateButton.inactive:hover {
  background-color: white;
  cursor: default;
}

.UpdateISPSubscription .updateButton:hover p, .UpdateISPSubscription .updatebutton.updating p {
  color: white;
}

.UpdateISPSubscription .updateButton.inactive:hover p {
  color: #666;
}

.UpdateISPSubscription .defaultMessage {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 20px;
  box-sizing: border-box;
}
