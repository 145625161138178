.UserTrialControl .noDataWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  color: #999;
}

.TrialControl {
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
}

.TrialControl strong {
  padding: 10px;
  min-width: 90px;
}

.TrialControl:first-of-type {
  border-top: none;
}


.TrialControl .fromNow.grey {
  color: '#999';
}

.TrialControl .changeButton {
  color: white;
  padding: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #555;
  margin-left: 10px;
  cursor: pointer;
}

.TrialControl .changeButton.available {
  background-color: #006DAB;
}
