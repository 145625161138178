.Nav {
  width: 75px;
  background: #444;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  display: none;
}

.Nav.open {
  display: flex;
  z-index: 30;
}

.TopBar {
  padding: 10px;
  background-color: #444;
  color: white;
  width: 100%;
}

.TopBar i {
  font-size: 22px;
}

.Nav .Logo {
  width: 45px;
  margin: 15px 0 35px;
}

.Nav .content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.Nav .content .first, .Nav .content .second {
  width: 100%;
}

.Nav .first {
  padding-bottom: 20px;
}

.Nav .second {
  padding-top: 20px;
  padding-bottom: 20px;
}

.Nav .section-border {
  border: 1px solid #DDD;
  width: 75%;
  opacity: 0.5;
}

.Nav .logout {
  margin-bottom: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 0.5px solid #333;
  background-color: #B90C0F;
  cursor: pointer;
  padding-left: 5px;
}

.Nav .logout:hover {
  background-color: transparent;
}

.Shade {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255, 0.75);
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .Shade {
    display: none;
  }
  .TopBar {
    display: none;
  }

  .Nav {
    position: static;
    display: flex;
  }
}