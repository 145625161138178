.DownloadHistory .history-row {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 5px 0;
}

.DownloadHistory .history-header {
  min-width: 55px;
}

.DownloadHistory .history-cell {
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
}

.DownloadHistory .circle {
  background-color: #168E32;
  height: 8px;
  width: 8px;
  border-radius: 4px;
}
