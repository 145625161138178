.TrialItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.TrialItem .cspName {
  width: 35%; 
}


@media screen and (max-width: 700px) {
  .TrialItem .cspName {
    width: 100%; 
  }
}