.Referrals {
  display: flex;
  flex-direction: column;
}

.Referrals .options {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.Referrals .Group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid #DDD;
  border-radius: 3px;
  padding: 8px 15px;
}

.Referrals .Group:hover {
  cursor: pointer;
}

.Referrals .Group.active p {
  color: white;
}
