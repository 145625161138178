.DatePicker {
  margin-top: 5px;
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.DatePicker calendarButtonWrap {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
}

.DatePicker .calendarWrap {
  position: absolute;
  right: -280px;
  top: 42px;
  background: white;
  /* border: solid 1px #ddd; */
  border-radius: 5px;
  border-top-left-radius: 0;
  z-index: 45;
  box-shadow: 10px 5px 20px rgba(0, 0, 0, .15);
}

.DatePickerClickAwayOverlay {
  position: absolute;
  top: 0;
  left: 85px;
  width: calc(100vw - 170px);
  height: calc(100vh - 60px);

  background-color: rgba(0, 0, 0, .05);
}

.DateRangePicker .rangeSelectors {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.DateRangePicker .rangeSelectors button {
  outline: none;
  border: solid 1px #ddd;
  border-right: none;
  min-width: 45px;
  height: 25px;
  font-size: 14px;
  color: #888;
  text-align: center;
}

.DateRangePicker .rangeSelectors button:last-child {
  border-right: solid 1px #ddd;
}

.DateRangePicker .rangeSelectors button.active,
.DateRangePicker .rangeSelectors button:active,
.DateRangePicker .rangeSelectors button:hover {
  background-color: #0477B4;
  color: white;
}

.DateRangePicker .rangeSelectors button.clear:active {
  background-color: white;
}