.User .details {
  padding: 20px;
  display: flex;
  align-items: center;
}

.User .details .left {
  align-self: flex-start;
  flex-basis: 1;
}

.User .details .right {
  align-self: flex-end;
  flex-basis: 1;
}

.User .edit-isps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}