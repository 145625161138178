.ConsoleLink {
  padding: 8px;
  cursor: pointer;
  color: #999;
  text-align: center;
  text-decoration: none;
  color: #f3a633;
}

.ConsoleLink:hover {
  cursor: pointer;
  opacity: 0.7;
}

.ConsoleLink:hover img {
  height: 18px;
}

.ConsoleLink .icon {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConsoleLink a {
  display: flex;
  text-decoration: none;
  align-items: center;
  color: #BB332A;
}

.ConsoleLink:hover {
  color: #BB332A;
  background-color: #f5f5f5;
}

.ConsoleLink p {
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 12px;
}
