.TagPopout {
  height: 100%;
  width: 100%;
  max-width: 450px;
  max-height: 350px;
  background-color: white;
  border: 1px solid #DDD;
  border-radius: 5px;
}

.TagPopout .popout-header {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.TagPopout .popout-header .close:hover {
  cursor: pointer;
}

.TagPopout .details {
  flex: 1;
}

.TagPopout .details-wrapper {
  padding: 20px;
  display: flex;
}

.TagPopout .data .label {
  margin-bottom: 5px;
  font-weight: 300;
  color: #999;
  font-size: 0.85em;
}

.TagPopout .details i:hover {
  cursor: pointer;
}
