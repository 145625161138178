.NoSubscription {
  text-align: center;
  font-weight: 400;
  color: #999;
  padding: 30px;
}

.NoSubscription .InfoLink {
  margin-top: 10px;
}

.CustomerSummary .body {
  flex: 1;
}

.CustomerSummary .body .statusTitle {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 18px;
}

.CustomerSummary .body .statusTitle strong {
  margin-right: 1ch;
  color: #168E32;
}

.CustomerSummary .iconWrap {
  width: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ddd;
  color: #006DAB;
}

.CustomerSummary .iconWrap i {
  font-size: 35px;
}

.CustomerSummary .iconWrap.hasProblem {
  color: #B90C0F;
}

.CustomerSummary .iconWrap.manage {
  color: #777;
  border-left: 1px solid #ddd;
  border-right: none;
  cursor: pointer;
}
.CustomerSummary .iconWrap.manage:hover {
  background-color: #ddd;
}

.CustomerSummary .cardIndicator {
  display: flex;
  align-items: center;
  padding: 20px;
}

.CustomerSummary .cardIndicator.good {
  color: #168E32;
}

.CustomerSummary .cardIndicator.bad {
  color: #B90C0F;
}

.CustomerSummary .cardIndicator p {
  font-size: 14px;
  margin: 0 5px 0 0;
}

.CustomerSummary .cardIndicator i {
  font-size: 18px;
}

@media screen and (min-width: 500px) {
  .CustomerSummary {
    display: flex;
    align-items: center;
  }
}
