.InputSelectClickAway {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .01);
}

.InputSelect {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.InputSelect:hover {
  opacity: 1;
}

.InputSelect > .InputLabel {
  font-size: 16px;
  margin-top: 10px;
  /* margin-left: 5px; */
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 0.85em;
  color: #666;
}

.InputSelect > .InputWrap {
  display: flex;
  border-radius: 5px;
}

.InputSelect > .InputWrap:hover {
  cursor: pointer;
}

.LockIconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: black;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin: 0
}

.PickerWrap {
  background-color: #EEE;
  border-width: 1;
  border-color: #DDD;
}
