* {
  box-sizing: border-box;
}

html, body, #wrap, #root, .App {
  min-height: fit-content;
  height: 100vh;
  font-weight: 300;
}

#root {
  font-weight: 300;
}

input:focus {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body #portal-root {
  position: fixed;
  top: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 10000;
  pointer-events: none;
}

body #portal-root > div {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
}