.List {
  background-color: white;
  border: 1px solid #DDD;
  border-radius: 5px;
  margin: 5px;
  min-width: 250px;
  position: relative;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  vertical-align: top;
  max-height: 95%;
}

.List .wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.List .cards {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
}

.List .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.List .top .name {
  flex: 1;
  padding: 10px 10px;
}

.List .top .options-trigger {
  padding: 5px;
  margin: 1px 5px;
  border-radius: 5px;
}

.List .top .options-trigger:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.List h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  color: #999;
}

.List .cards .default {
  padding: 10px 10px;
  display: flex;
  align-items: center;
}

.List .cards .default .spinner-wrap {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.List .cards .default p {
  font-size: 12px;
  color: #999;
}
