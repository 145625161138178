.ISPCard {
  margin: 0;
  border-radius: 0;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ISPCard.border-left {
  border-left: 1px solid transparent;
}

.ISPCard .card-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.ISPCard.full:last-of-type {
  /* border-bottom: 0px solid #DDD; */
}

.ISPCard:hover {
  cursor: pointer;
}

.ISPCard .section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.ISPCard .isp-name {
  flex: 1;
  max-width: 80%;
}

.ISPCard .isp-name p {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
}

.ISPCard .isp-name span {
  color: #666;
  font-size: 12px;
  margin-left: 5px;
}

.ISPCard .isp-status {
  display: flex;
  align-items: center;
}

.ISPCard .isp-status p {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
}

.ISPCard .account-value p {
  font-size: 12px;
  color: rgb(22, 142, 50);
}

.ISPCard .isp-activity {
  display: flex;
  align-items: center;
}

.ISPCard .isp-activity p {
  font-size: 10px;
  margin: 0 2px;
}

.ISPCard .sales-dev {
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.ISPCard .sales-dev .tag {
  background-color: #E8E8E8;
  padding: 3px 5px;
  border-radius: 10px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ISPCard .sales-dev .tag:hover {
  background-color: #DDD;
  cursor: pointer;
}

.ISPCard .sales-dev .tag p {
  font-size: 8px;
  color: black;
  font-weight: 100;
}

.ISPCard .tag-section {
  background-color: white;
  display: flex;
  overflow: scroll;
}

.ISPCard .tag-section::-webkit-scrollbar {
  display: none;
}
