.SalesTagger {
  padding: 20px;
}

.SalesTagger .active {
  padding: 10px;
  display: flex;
}

.SalesTagger .new {
  position: relative;
}

.SalesTagger .matches {
  position: absolute;
  top: 45px;
}

.SalesTagger .matches .option {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #DDD;
}

.SalesTagger .matches .option:hover {
  cursor: pointer;
  background-color: #999;
}

.SalesTagger .tag {
  background-color: #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 5px;
}

.SalesTagger .tag:hover {
  cursor: pointer;
}

.SalesTagger .tag p {
  color: black;
  margin-left: 10px;
  font-size: 14px;
}

.SalesTagger .remove-tag:hover {
  cursor: pointer;
}

.SalesTagger .tag-body {
  flex: 1;
}

.SalesTagger .tag-body:hover  {
  cursor: pointer;
}
