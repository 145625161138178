.StatusEventForm {
  min-width: 320px;
  background-color: white;
  border: solid 1px #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, .1);
}

.StatusEventForm .severity-buttons-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.StatusEventForm .markdown-toggle-button-wrap {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-around;
  align-items: center;
}

.StatusEventForm .markdown-preview-wrap {
  flex: 1;
  min-height: 270px;
  border: solid 1px #ddd;
  margin-top: 20px;
  overflow: scroll;
}

.StatusEventForm .close-button-wrap {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.StatusEventForm .close-button {
  border: solid 2px #ddd;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.StatusEventForm .close-button:hover {
  background-color: #efefef;
}