.SectionTitle {
  display: flex;
  justify-content: space-between;
  width: '100%';
  padding-bottom: 10px;
  margin: 10px;
}

.SectionTitle h3 {
  font-weight: 300;
  font-size: 1.25em;
  color: #444;
  margin: 0;
}
