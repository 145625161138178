.Calendar {
  min-width: 300px;
  min-height: 300px;
  background-color: white;
  /* border: solid 1px #ddd; */
  border-radius: 5px;
  height: 100%;
  width: 100%;
  user-select: none;
}

.Calendar.picker {
  height: 300px;
  width: 300px;
}

.Calendar > .Selectors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Calendar.picker > .Selectors {
  height: 60px;
  max-width: 100%;
}


.Calendar > .Selectors > .SelectorRow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  max-width: 100%;
}

.Calendar > .Selectors > .SelectorRow > .selectorCol {
  width: 33%;
}

.Calendar > .PeriodWrap {
  height: calc(100% - 40px);
}

.Calendar.picker > .PeriodWrap {
  height: calc(100% - 60px);
}

.Calendar .Year {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 5px;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .Calendar > header {
    flex-direction: column;
    height: 90px;
  }

  .Calendar > .PeriodWrap {
    height: calc(100% - 90px);
  }

  .Calendar .Year {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }

  .Calendar.mobile > .Selectors > .SelectorRow,
  .Calendar.picker > .Selectors > .SelectorRow {
    flex-direction: column;
    /* height: 50px; */
  }
}

.Calendar .Year .Months {
  height: 100%;
  width: 100%;
}

.Calendar .Year .Months > header {
  min-height: 15px;
  font-size: 14px;
}

.Calendar .Month {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 20px repeat(6, 1fr);
  height: 100%;
  width: 100%;
  /* gap: 5px; */
  padding: 5px;
}

.Calendar.picker .Month {
  grid-template-rows: repeat(6, 1fr);
}

.Calendar .Year .Months .Month {
  height: calc(100% - 15px);
  padding: 0px;
}

.Calendar .Week {
  display: grid;
  position: relative;
  grid-template-columns: repeat(7, 1fr);
  /* grid-gap: 5px; */
  width: 100%;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.Calendar .DaysOfWeek {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  height: 20px;
  align-items: center;
  justify-content: center;
}

.Calendar .Year .DaysOfWeek {
  font-size: 11px;
}

.Calendar .Day {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 3px;
  cursor: pointer;
}

.Calendar.picker .Day {
  padding: 0px;
}

.Calendar .Day.year {
  padding: 0;
}

.Calendar .Day .DayWrap {
  width: 100%;
  height: 100%;
  /* border: solid 1px #ddd; */
  border-radius: 5px;
}

.Calendar .Day.year .DayWrap,
.Calendar .Day.picker .DayWrap {
  border-radius: 0px;
}

.Calendar .Day header {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.Calendar .Day.year header,
.Calendar .Day.picker header {
  padding: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.Calendar .Day.year .DayWrap,
.Calendar .Day.picker .DayWrap {
  padding: 0;
  width: 100%;
}

.Calendar .Day header p {
  text-align: right;
}

.Calendar .Day.year header p,
.Calendar .Day.picker header p {
  text-align: center;
}

.Calendar .Day .MouseOverCard {
  position: absolute;
  pointer-events: none;
  z-index: 5;
  min-height: 100px;
  min-width: 100px;
  max-height: 250px;
  max-width: 25px;
  height: fit-content;
  width: fit-content;
  background-color: white;
  border-radius: 10px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, .07);
}

.Calendar .SpanLabel {
  text-align: center;
}

.Calendar .Week .PeriodBar {
  position: absolute;
  z-index: 2;
  bottom: 8px;
  left: 0px;
  right: 0px;
  height: 30px;
  border: solid 1px #888;
}