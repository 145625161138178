.Refresh {
  display: flex;
  align-items: center;
}

.Refresh .action:hover {
  cursor: pointer;
}

.Refresh .action.disabled:hover {
  cursor: default; 
}
