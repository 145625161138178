.InputTextArea {
  width: 100%;
}

.InputTextArea label, .InputText textarea {
  display: block;
}

.InputTextArea textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #DDD;
  border-radius: 5px;
  font-size: 1.05em;
  font-weight: 300;
  padding: 10px;
}

.InputTextArea textarea:focus {
  outline: none;
}

.InputTextArea ::placeholder {
  color: #BBB;
  opacity: 1;
}

.InputTextArea label {
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 0.85em;
  color: #666;
  display: flex;
  align-items: center;
}

.InputTextArea label i {
  margin-left: 5px;
}
