.LineItem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #ddd;
}

.LineItem .title {
  flex: 1;
  margin-bottom: 3px;
  font-weight: 400;
}

.LineItem .description {
  flex: 1;
  padding: 0 15px;
}

.LineItem .cost {
  font-weight: 400;
}
