.ApiTestTool .buttonWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.ApiTestTool .buttonWrap button {
  margin: 0 0 0 20px;
}

.ApiTestTool .contactId {
  font-size: 20px;
}

.ApiTestTool .contactId span {
  color: #999;
}

.ApiTestTool .testStatus {
  text-align: center;
  margin: 0px 10px 15px;
}

.ApiTestTool .testStatus.error {
  color: #B90C0F;
}

.ApiTestTool .testStatus.success {
  color: #168E32;
}

.ApiTestTable {
    padding: 10;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.ApiTestTable::-webkit-scrollbar {
  display: none;
}

.ApiTestTable {
  font-weight: 400;
}

.ApiTestTable h5.issue {
  color: #B90C0F;
}

.ApiTestTable .missingAreaWrapper {
  display: flex;
  justify-content: center;
  max-width: 100%;
  flex-wrap: wrap;
}

.ApiTestTable .missingArea {
  padding: 5px;
}

.ApiTestTable .missingArea.paid {
  color: #B90C0F;
}

.ApiTestTable h5 {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.ApiTestTable table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  padding: 10px;
}

.ApiTestTable thead {
  border-bottom: 1px solid #DDD;
}

.ApiTestTable thead th {
  font-weight: 400;
  font-size: 14px;
  padding: 3px 5px;
}

.ApiTestTable tbody tr {
  border-bottom: 1px solid #DDD;
}

.ApiTestTable tbody td {
  font-size: 12px;
  font-weight: 300;
  padding: 3px 10px;
}

.ApiTestTable .statusTask {
  display: flex;
  border-left: 1px solid #ddd;
  padding-left: 20px;
  margin-left: 20px;
}

.ApiTestTable tr.inactive {
  background-color: #f5f5f5;
}

.ApiTestTable tr.inactive td {
  font-weight: 300;
  color: #666;
  padding: 3px 10px;
}

.ApiTestTable tr td.active {
  color: #168E32;
}

.ApiTestTool .confirm-delete {
  min-height: 75px;
  min-width: 150px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 121;
}

.ApiTestTool .confirm-buttons {
  margin-top: 10px;
  display: flex;
}
