.Device {
  padding: 5px;
  width: 100px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Device.active {
  outline: 3px solid #006DAB;
}

.Device i {
  align-content: center;
}

.Device .info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Device div p{
  font-size: 18px;
  color: #999;
  text-align: center;
}

.Device div small {
  color: #999;
  text-align: center;
}

.Device .info .browser,
.Device .info .model {
  font-weight: bold;
}