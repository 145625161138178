.IspGhostTools .Ghost {
	display: flex;
	align-items: center;
	padding: 15px 20px;
}

.IspGhostTools .Ghost:not(:last-child) {
	border-bottom: 1px solid #ddd;
}

.IspGhostTools .Ghost h3 {
	margin: 0;
	font-weight: 200;
	color: #999;
	flex: 1;
	font-size: 18px;
}

.IspGhostTools .Ghost h3 strong {
	font-weight: 400;
	color: black;
}

.IspGhostTools .ghostError {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	color: red;
	text-align: center;
}