.Articles {
  flex: 1;
}

.Articles .Button {
  margin: 0;
}

.Article .data-row {
  padding: 5px 10px;
  border-bottom: 1px solid #DDD;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Article .data-row:nth-child(even) {
  background-color: #FCFCFC;
}

.Article .data-row:last-child {
  border-bottom: 0;
}

.Article .data-row.highlight {
  border: 1px solid yellow;
  background-color: yellow;
  opacity: 0.25;
}
