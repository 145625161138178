.IconPicker {
  margin-top: 5px;
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.IconPicker .pickerWrap {
  position: absolute;
  right: -280px;
  top: 42px;
  background: white;
  border-radius: 5px;
  border-top-left-radius: 0;
  z-index: 45;
  box-shadow: 10px 5px 20px rgba(0, 0, 0, .15);
  display: flex;
  flex-direction: column;
  align-content: center;
}

.IconPicker .pickerWrap .pickerTitle {
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  margin: 5px;
}

.IconPickerClickAwayOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 60px);
  width: calc(100vw - 85px);
  background-color: rgba(0, 0, 0, .03);
}