.IspSubscription .IspName {
  font-size: 20px;
  padding: 20px;
  width: 100%;
  border-bottom: 1px solid #DDD;
}

.IspSubscription .IspName span {
  font-size: 14px;
  margin-left: 5px;
  color: #555;
}

.IspSubscription .sectionButtons {
  display: flex;
  border-bottom: 1px solid #DDD;
}

.IspSubscription .sectionButtons .sectionButton {
  flex: 1;
  text-align: center;
  padding: 15px;
  color: #777;
  cursor: pointer;
}

.IspSubscription .sectionButtons .sectionButton.active {
  color: #006DAB;
}

.IspSubscription .sectionButtons .sectionButton:hover {
  background-color: #006DAB;
  color: white;
}

.IspSubscription .sectionButtons .sectionButton:not(:first-child) {
  border-left: 1px solid #DDD;
}

.IspSubscription .noData {
  padding-top: 20px;
  color: #999;
}