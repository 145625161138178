.Notice {
  min-width: 40px;
  max-width: 100%;
  min-height: 30px;
  background-color: #E33300;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.Notice.warning {
  background-color: #FF7702;
}

.Notice.info {
  background-color: #0777B4;
}

.Notice:hover {
  background-color: #FF4C18;
}

.Notice.warning:hover {
  background-color: #FFA502;
}

.Notice.info:hover {
  background-color: #1686C3;
}

.Notice .label {
  font-weight: 200;
  font-size: 20px;
  padding: 10px;
  margin: 5px;
  color: white;
}

.Notice .loading {
  margin: 5px;
}

.Notice .content {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.Notice .title {
  font-size: 23px;
  font-weight: 500;
  color: white;
  margin: 0px;
}

.Notice .description {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 300;
  color: white;
}