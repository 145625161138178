.SignupMetricsSummaries {
  display: flex;
  flex-direction: column;
  height: 300px;
}

.SignupMetricsSummaries .top {
  flex: 3;
  /* border-bottom: 1px solid #DDD; */
  display: flex;
}

.SignupMetricsSummaries .totals-wrapper {
  display: flex;
  flex: 1;
}

.SignupMetricsSummaries .chart-wrapper {
  display: none;
  flex: 5
}

.SignupMetricsSummaries .calendar-wrapper {
  display: flex;
  flex: 25;
}

.SignupMetricsSummaries .calendar-wrapper .list-wrapper {
  display: flex;
  flex: 1;
}

@media screen and (min-width: 768px) {
  .SignupMetricsSummaries .chart-wrapper {
    display: flex;
  }
}
