.PageTabs {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}

.PageTabs .tab {
  text-decoration: none;
  color: black;
  padding: 10px 15px;
  background-color: white;
  border: 1px solid #DDD;
  margin: 5px;
  border-radius: 3px;
}

.PageTabs .tab.active {
  background-color: #444444;
  color: white;
}
