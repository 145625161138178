.SectionButton {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  display: block;
  border-radius: 3px;
  font-size: 0.9em;
  text-align: center;
  cursor: pointer;
  margin: 0;
}

.SectionButton:focus {
  outline: none;
}

.SectionButton p {
  margin: 0;
}
