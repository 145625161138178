.SearchResults {
  background-color: white;
  border: 1px solid #DDD;
  border-top: none;
  margin-bottom: 30px;
}

.SearchResults .noData {
  text-align: center;
  padding: 30px;
  color: #B90C0F;
}

.SearchResults .searching {
  text-align: center;
  padding: 30px;
}
