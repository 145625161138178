.UserProfile {
  margin: 0;
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  padding: 5px 5px 5px;
}

.UserProfile .section {
  min-height: 300px;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.UserProfile .section .left,
.UserProfile .section .right {
  width: 50%;
}

.UserProfile .section .info {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.UserProfile .section .left {
  border-right: 1px solid #DDD;
}


.UserProfile .section .info .data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #DDD;
  font-size: 14px;
}

@media only screen
and (max-device-width: 600px) {
  .UserProfile .section .info .data {
    flex-direction: column;
  }  
}

.UserProfile .section .data p:first-child {
  font-weight: 500;
}

