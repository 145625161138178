.TooltipWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  font: inherit;
  margin: 0;
  padding: 0;
}

.Tooltip {
  position: absolute;
  top: 0px;
  left: -20px;
  z-index: 1000;
  min-width: 20px;
  min-height: 20px;
  width: fit-content;
  padding: 10px;
  background-color: #000;
  color: #FFF;
}