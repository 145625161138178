.Picker {
  cursor: pointer;
}

.Picker .selectorWrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Picker .selectorWrap .Selected {
  flex: 1;
}

.PickerDropdown {
  position: relative;
  width: 100%;
}

.PickerDropdown > .Picker {
  position: absolute;
  z-index: 55;
  min-height: fit-content;
  /* height: fit-content; */
  max-height: 300px;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: solid 1px #DDD;
  border-top: none;
  cursor: pointer;
}

.PickerItem {
  min-height: 28px;
  background-color: #FBFBFB;
  padding: 5px 1px;
  text-align: center;
}

.PickerItem.selected {
  background-color: white;
  /* border: solid 1px #DDD; */
  color: black;
  font-weight: 400;
}

.Picker:hover, 
.PickerItem:hover {
  background-color: #DDD;
  cursor: pointer;
  /* outline: solid 1px #BBB; */
}

.PickerClickAwayOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  background-color: rgba(0, 0, 0, 0);
}
