.SalesLists {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  position: relative;
}

.SalesLists .header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SalesLists h1 {
  margin: 0;
  font-size: 24px;
  color: #999;
  font-weight: 300;
}

.SalesLists .board-wrapper {
  position: relative;
  flex: 1;
  display: flex;
}

.SalesLists .board {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-x: scroll;
}

.SalesLists .confirm-delete {
  min-height: 75px;
  min-width: 150px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 121;
}

.SalesLists .confirm-buttons {
  margin-top: 10px;
  display: flex;
}
