.NoteTags {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.NoteTags .tag {
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid #DDD;
  padding: 3px;
  margin-right: 5px;
}

.NoteTags .tag.active {
  background-color: #999;
}

.NoteTags .tag.active p {
  color: white;
}

.NoteTags .tag:hover p {
  color: white;
}

.NoteTags .tag:hover {
  background-color: #999;
  cursor: pointer;
}

.NoteTags .tag.active:hover {
  background-color: #444;
}
