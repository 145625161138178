.LifecycleOptions {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.LifecycleOptions .Group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid #DDD;
  border-radius: 3px;
  padding: 8px 15px;
}

.LifecycleOptions .Group:hover {
  cursor: pointer;
}

.LifecycleOptions .Group.active p {
  color: white;
}
