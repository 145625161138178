.WorkAreaEntry .top {
  display: flex;
  align-items: center;
  padding: 15px 20px 15px 0;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
}

.WorkAreaEntry .top:hover {
  background-color: #f5f5f5;
}

.WorkAreaEntry:last-child .top {
  border-bottom: none;
}

.WorkAreaEntry .activeLightWrap {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.WorkAreaEntry .activeLight {
  background-color: #DDD;
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.WorkAreaEntry h3 {
  margin: 0;
  font-weight: 300;
  color: #999;
}

.WorkAreaEntry .id {
  flex: 1;
}

.WorkAreaEntry h3 > span, .WorkAreaEntry .id {
  color: black;
}

.WorkAreaEntry .paid, .WorkAreaEntry .unpaid, .WorkAreaEntry .manual {
  font-size: 14px;
  font-weight: 500;
  color: #999;
}

.WorkAreaEntry .manual {
  margin-right: 10px;
}

.WorkAreaEntry .paid {
  color: #168E32;
}

.WorkAreaEntry > .body {
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  flex: 1;
  flex-direction: column;
}

.WorkAreaEntry .body .row {
  display: flex;
  flex: 1;
}


.WorkAreaEntry .body .row .section {
  display: flex;
  flex: 1;
}

.WorkAreaEntry .body .pad {
  padding: 10px;
}

.WorkAreaEntry .body .border-right {
  border-right: 1px solid #f5f5f5;
}

.WorkAreaEntry .body .border-top {
  border-top: 1px solid #f5f5f5;
}

.WorkAreaEntry .body .row .section .map {
  flex: 1;
}

.WorkAreaEntry .body .row.space {
  justify-content: space-between;
}

.WorkAreaEntry .data-point {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.WorkAreaEntry .data-point i {
  margin-right: 10px;
}

.WorkAreaEntry .body .row .section.column {
  flex-direction: column;
}

.WorkAreaEntry h4 {
  margin: 0;
  color: #999;
  font-weight: 300;
}

.WorkAreaEntry .activeLight.active {
  background-color: #FF9200
}
