.TransferBillingModal {
  display: flex;
  flex-direction: column;
  max-height: 1200;
  max-width: 1400px;
  z-index: 25;
  justify-content: space-between;
  border-radius: 5px;
  position: relative;
  padding: 10px;
  background-color: #f5f5f5;
}

.TransferBillingModal .mainContainer {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.TransferBillingModal .BillingDataView {
  flex: 1;
  border: 1px solid #ddd;
  background-color: white;
}

.TransferBillingModal .spinnerWrap {
  height: 50px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TransferBillingModal .errorMessage {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.TransferBillingModal .dataView h3 {
  margin: 0 10px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #f5f5f5;
}

.TransferBillingModal .dataView p {
  display: flex;
  align-items: center;
  color: #999999;
  margin: 0 20px 0 30px;
  font-size: 12px;
  min-height: 30px;
}

.TransferBillingModal .dataView p .StripeLink {
  margin: 0;
  padding: 5px;
}

.TransferBillingModal .dataView p.issue {
  color: #FC0000;
}

.TransferBillingModal .dataView p.new {
  color: #168E32;
}

.TransferBillingModal .dataView p span {
  color: black;
}

.TransferBillingModal .existingEntry {
  background-color: #168E32;
  text-align: center;
  color: white;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
}

.TransferBillingModal .NewOwnerPicker {
  background-color: #CCC;
}

.TransferBillingModal .InputSelect {
  height: 40px;
}

.TransferBillingModal .submitButton {
  text-align: center;
  padding: 10px;
  background-color: #168E32;
  color: white;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
}