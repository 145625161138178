.ActiveISPList {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ActiveISPList .search {
  flex: 1;
  display: flex;
}

.ActiveISPList .search input {
  width: 100%;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
}

.ActiveISPList .ActiveListItem {
  border-top: 1px solid #DDD;
  padding: 5px 10px;
}

.ActiveISPList .ActiveListItem:first-child {
  border-top: 0px solid #DDD;
}

.ActiveISPList .ActiveListItem:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.ActiveISPList .scrollable {
  display: flex;
  flex-direction: column;
  height: 335px;
  overflow-y: scroll;
  border-top: 1px solid #DDD;
}

.ActiveISPList .scrollable::-webkit-scrollbar {
    width: 0.5em;
    background-color: #f5f5f5;
}

.ActiveISPList .scrollable::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px #DDD;
}

.ActiveISPList .scrollable::-webkit-scrollbar-thumb {
  background-color: #DDD;
  outline: 1px solid #f5f5f5;
  border-radius: 6px;
}

.ActiveISPList .scrollable .calendar-date {
  display: flex;
  padding: 0 10px;
  min-height: min-content;
  min-height: -moz-min-content;    /* Firefox */
  min-height: -webkit-min-content; /* Chrome */
}
