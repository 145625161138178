.MultiToggle {
  display: flex;
  flex-direction: row;
  padding: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
  max-width: fit-content;
}

.MultiToggle .btn {
  padding: 10px 20px;
  border-radius: 5px;
  min-width: 80px;
}

.MultiToggle .btn p {
  font-weight: 500;
  text-align: center;
}

.MultiToggle .btn.hover:hover {
  cursor: pointer;
  opacity: 0.75;
  background-color: #ddd;
}

.MultiToggle .btn.active p {
  color: white;
}
