.ispLink {
  padding: 8px;
  color: black;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.ispLink .descriptive, .ispLink a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.ispLink.active {
  box-shadow: 0 -2px 0 #006DAB inset;
}

.ispLink.clickable:hover {
  color: white;
  background-color: #006DAB;
  cursor: pointer;
}

.ispLink.clickable:hover .icon i {
  color: white !important;
}

.ispLink .icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.ispLink .icon i {
  color: #006DAB !important;
}

.ispLink.clickable:hover p {
  color: white;
}

.ispLink p {
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 14px;
}
