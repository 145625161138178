.EditUserISPs .authorize-operation-wrap .IspEntry {
  border: solid 1px #ddd;
}

.EditUserISPs .authorize-operation-wrap .authorize-operation-button-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.EditUserISPs .authorize-operation-wrap .authorize-operation-button-wrap .Button {
  margin: 15px;
  margin-bottom: 0px;
}