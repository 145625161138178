.CRM {
  position: relative;
  margin: 0;
  min-height: 900px;
}

.CRM .section {
  display: flex;
  flex: 1;
}

.CRM .vertical {
  flex-direction: column;
}

.CRM .section.topBorder {
  border-top: 1px solid #DDD;
}

.CRM .section.rightBorder {
  border-right: 1px solid #DDD;
}
