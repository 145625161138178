.StatusEventPreview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100px;
  padding: 10px;
}

.StatusEventPreview h2 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.StatusEventPreview .title-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.StatusEventPreview .title-wrap h2 {
  margin-left: 5px;
}

.StatusEventPreview .top-row {
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
.StatusEventPreview .action-buttons-wrap {
  display: flex;
}