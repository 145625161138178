.StationAdvocate {
  display: flex;
  align-items: center;
  margin: 0 25px;
}

.StationAdvocate .locked {
  opacity: 0.8;
}

.StationAdvocate .locked:hover {
  opacity: 1;
  cursor: pointer;
}

.StationAdvocate .text {
  text-decoration: line-through;
  color: #999;
  font-size: 14px;
  margin-left: 5px;
  background-color: #CCC;
  padding: 5px;
  border-radius: 3px;
}

.StationAdvocate .text.active {
  text-decoration: none;
  background-color: rgb(185, 12, 15);
  color: white;
}

.StationAdvocate .text.unlocked:hover {
  background-color: #BBB;
  cursor: pointer;
}

.StationAdvocate .text.active.unlocked:hover {
  background-color: rgb(200, 12, 15);
}
