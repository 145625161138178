.Bill .dates {
  color: #999;
  border-top: 1px solid #DDD;
  text-align: center;
  padding: 3px;
}

.Bill > h1 {
  text-align: center;
  font-weight: 400;
  margin: 15px;
  color: #168E32;
  font-size: 22px;
}

.Bill.preview > h1 {
  color: #006DAB;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Bill.preview > h1 .hoverQWrap {
  position: absolute;
  right: 10px;
}

.Bill .LineItem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #DDD;
}

.Bill .LineItem .itemType {
  margin-bottom: 3px;
  font-weight: 400;
}

.Bill .LineItem .description {
  flex: 1;
  padding-right: 20px;
  color: #555;
}

.Bill .LineItem .cost {
  font-weight: 400;
  color: #555;
}

.Bill .LineItem .cost.credit, .Bill .LineItem .cost.isDiscounted {
  color: #168E32;
}

.Bill .LineItem.total {
  color: black;
  font-weight: 400;
  padding: 20px;
}

.Bill .LineItem.total .cost, .Bill .LineItem.total .description  {
  color: black;
}

.Bill .ItemTypeIcon {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  font-size: 18px;
}

.Bill .historyLink {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-top: 1px solid #DDD;
  color: #006DAB;
  cursor: pointer;
}