.Card {
  border-top: 1px solid #DDD;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.Card span {
  margin: 0;
  font-weight: 300;
  font-size: 1em;
  color: #999;
  display: block;
}

.Card span:first-child {
  flex: 1;
  padding: 0 0.75em 0 0;
}

.Card .iconWrap {
  width: 50px;
  padding: 20px 0;
  margin-right: 10px;
  border-right: 1px solid #DDD;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #006DAB;
}

.Card .name {
  flex: 1;
}