.CategoryCard {
  display: flex;
  align-items: center;
}

.CategoryCard .numArticles {
  margin-left: 10px;
  color: #006DAB;
  font-size: 12px;
}
