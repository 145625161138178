.FirebaseTextLink {
  padding: 8px;
  cursor: pointer;
  color: #999;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #f3a633;
}

.FirebaseTextLink:hover {
  color: white;
  background-color: #f3a633;
}

.FirebaseTextLink .icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: white;
  background-color: #f3a633;
  font-weight: 500;
  font-size: 14px;
}

.FirebaseTextLink p {
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 12px;
}
