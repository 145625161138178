.StripeLink {
  padding: 8px;
  cursor: pointer;
  color: #999;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #006DAB;
}

.StripeLink:hover {
  color: white;
  background-color: #006DAB;
}

.StripeLink .icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: white;
  background-color: #006DAB;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.StripeLink p {
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 12px;
}