.PipelineStatus {
  border: 1px solid #DDD;
  padding: 1px 3px;
  border-radius: 3px;
}

.PipelineStatus p.status {
  font-size: 10px;
  font-weight: 300;
}
