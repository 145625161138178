.TransferHistoryList {
  display: flex;
  flex-direction: column;
}

.TransferHistoryListItem {
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.TransferHistoryListItem:last-child {
  border-bottom-width: 0px;
}

.TransferHistoryListItem .info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.TransferHistoryListItem .owner-row {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TransferHistoryListItem .owner-info {
  min-width: 200px;
}

.TransferHistoryListItem .stripe-row {
  display: flex;
  align-items: center;
}