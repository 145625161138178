.EnabledIsp {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDD;
  padding-right: 20px;
  cursor: pointer;
  text-decoration: none;
}

.EnabledIsp:hover {
  background-color: #f5f5f5;
}

.EnabledIsp.inactive:hover {
  background-color: transparent;
}

.EnabledIsp:last-child {
  border-bottom: none;
}

.EnabledIsp .iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  padding: 5px 0;
}

.EnabledIsp .entryBody {
  flex: 1;
  display: flex;
  justify-content: left;
}


.EnabledIsp h2 {
  color: #999;
  font-weight: 300;
  margin: 0;
  font-size: 18px;
}

.EnabledIsp h4 {
  color: #999;
  font-weight: 300;
  margin: 0;
  font-size: 13px;
}

.EnabledIsp h2 > span, .EnabledIsp h4 > span {
  color: black;
}

.EnabledIsp .ispTitle {
  display: flex;
  align-items: center;
}

.EnabledIsp .ispTitle > span:last-child {
  color: #999;
}

.EnabledIsp .ispTitle > span {
  max-width: 50%;
  display: block;
}

.EnabledIsp .stationWrap {
  display: flex;
  align-items: center;
  width: 80px;
}

.EnabledIsp .stationWrap > h4 {
  margin-left: 5px;
}

.EnabledIsp .owner {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.EnabledIsp .owner > h4 {
  margin-left: 5px;
}
