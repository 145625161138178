.SalesCycle {
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
}

.SalesCycle p {
  color: #666;
  margin: 0 5px;
  font-size: 14px;
}
