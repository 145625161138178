.StationISPListItem {
  color: black;
  display: flex;
  padding: 5px 10px;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid #f5f5f5;
  align-items: center;
  justify-content: space-between;
}

.StationISPListItem:hover {
  background-color: #f5f5f5;
}

.StationISPListItem > .cspName {
  margin: 0;
  margin-right: 5px;
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.StationISPListItem > .stationNum {
  color: #444;
  font-size: 14px;
}

.StationISPListItem .info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.StationISPListItem .info .infoWrap {
  margin-left: 10px;
}
