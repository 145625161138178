p, h4 {
  margin: 0;
}

.AssociateISP .search {
  padding: 20px;
}

.AssociateISP .status {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.AssociateISP .status div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.AssociateISP .status p {
  font-size: 16px;
  margin-left: 5px;
}

.AssociateISP .status button p {
  margin: 0;
  color: white;
  font-weight: 300;
  padding: 5px 10px;
}

.AssociateISP .status button {
  border-radius: 3px;
}

.AssociateISP .status button:hover {
  cursor: pointer;
}

.AssociateISP .status button:focus {
  outline: none;
}
