.PipelineList {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin-bottom: 20px;
  position: relative;
}

.PipelineList .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #DDD;
  /* border-top: 1px solid #DDD; */
  /* border-right: 1px solid #DDD; */
  /* border-bottom: 1px solid #DDD; */
}

.PipelineList .summary {
  height: 50px;
  min-height: 50px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
}

.PipelineList .circle {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  border: 1px solid #DDD;
}

.PipelineList .info {
  padding: 5px 10px;
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.PipelineList .info .title {
  display: flex;
  align-items: center;
}

.PipelineList .info h1 {
  font-size: 16px;
  color: black;
  font-weight: 300;
  margin-right: 5px;
}

.PipelineList .cards {
  overflow-y: scroll;
}

.PipelineList .deals {
  display: flex;
  align-items: center;
}

.PipelineList .deals p {
  font-size: 12px;
  font-weight: 300;
}

.PipelineList .deals p:first-of-type {
  margin-right: 10px;
  color: #666;
}

.PipelineList .deals p:nth-of-type(2) {
  font-size: 10px;
  color: #999;
}

.PipelineList .cards .default {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
}

.PipelineList .cards .default .spinner-wrap {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PipelineList .cards .default p {
  font-size: 12px;
  color: #999;
}

.PipelineList .info .options-trigger {
  margin: 1px 5px;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  right: 3px;
}

.PipelineList .info .options-trigger:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
