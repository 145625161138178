.PageTopBar .wrap {
  display: flex;
  min-height: 60px;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #DDD;
  background-color: #F5F5F5;
}

.PageTopBar .wrap .iconWrap {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PageTopBar .childrenWrapper {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 120px);
}

