.FirebaseLink {
  padding: 5px;
  cursor: pointer;
  color: #999;
  text-align: center;
  padding-top: 8px;
}

.FirebaseLink:hover {
  transform: scale(1.4)
}
