.FeatureFlags .error-text-wrap {
  width: 100%;
  height: 100%;
  min-height: 10px;
  color: red;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: normal;
}

.FeatureFlags .loading-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  background-color: rgba(255, 255, 255, .1);
  max-height: 80vh;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: normal;
}

.FeatureFlags .edit-form-wrap {
  padding: 20px;

}

.FeatureFlagEditForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FeatureFlagEditForm > .delete-button-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.FeatureFlagEditForm > .input-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.FeatureFlagEditForm > .button-wrap {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.FeatureFlagOperationsList .search-input-wrap {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.FeatureFlagOperationsList .search-input-wrap .Button {
  max-height: 40px;
  margin-left: 10px;
}

.FeatureFlagOperationsList .operations-loading-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 200px;
}

.FeatureFlagOperationsList .scroll-list {
  max-height: 500px;
  overflow-y: scroll;
}

.FeatureFlagOperation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

}

.FeatureFlagOperation .name-wrap {
  display: flex;
  flex-direction: row;
}

.FeatureFlagController .button-wrap {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.FeatureFlagController .button-wrap .Button {
  margin: 0 0;
}

.FeatureFlagController .button-wrap .edit-buttons-wrap {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.FeatureFlagController .button-wrap .edit-buttons-wrap .Button:last-child {
  margin-left: 10px;
}