.no-permission {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.no-permission h1 {
  font-weight: 300;
}
