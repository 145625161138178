.ContactIdTools h3 {
  margin: 0;
  text-align: center;
  font-weight: 300;
  padding: 10px;
  font-size: 16px;
}

.ContactIdTools h4 {
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-weight: 300;
  padding: 0 0 10px 0;
  font-size: 14px;
}

.ContactIdTools .buttonWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.ContactIdTools .buttonWrap .Button {
  margin: 0;
}

.ContactIdTools .buttonWrap .Button:not(:first-child) {
  margin-left: 15px;
}

.ContactIdTools .statusTask {
  display: flex;
  border-left: 1px solid #ddd;
  padding-left: 20px;
  margin-left: 20px;
}
