.SignupInfo {
  width: 65%;
  display: grid;
  grid-template-columns: 3fr .2fr .5fr .7fr 2.2fr 1fr;
  gap: 10px;
}

.SignupInfo .info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.SignupInfo .info p {
  font-size: 12px;
  margin-right: 5px;
}

.driversAndAuthorizedUsers {
  display: flex;
}

@media screen and (max-width: 700px) {
  .SignupInfo {
    grid-template-columns: 3fr .2fr .3fr .5fr 3.5fr 2fr;
    width: 100%;
    gap: 1px;
  }
}
@media screen and (min-width: 2000px) {
  .SignupInfo {
    width: 600px;
  }
}
